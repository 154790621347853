// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"col-lg-2-25\" style=\"height:100%\">\n	<div class=\"scroll-y-auto callout eventsFilterMenu\" style=\"height:"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"filterDivHeight") || (depth0 != null ? lookupProperty(depth0,"filterDivHeight") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"filterDivHeight","hash":{},"data":data,"loc":{"start":{"line":2,"column":67},"end":{"line":2,"column":86}}}) : helper)))
    + "; overflow-x: hidden\">\n		<div class=\"eventsCollectionFilter\"></div>\n	</div>\n	<div class=\"eventsAskAdminView\" />\n</div>\n\n<div class=\"col-lg-9-75\"  style=\"height:100%\">\n	<div class=\"eventsCollectionView\"></div>\n</div>\n";
},"useData":true});
