/*
	Model/collection to fetch events objects follow status from server.
*/
var Backbone = require('backbone')

var eventsColumns = require('../../../common/config/constants').eventsFieldNames

var EventsFollowModel = Backbone.Model.extend({
  urlRoot: '/api/protected/eventsFollow',
  idAttribute: eventsColumns.EVENT_ID,
})

var EventsFollowCollection = Backbone.Collection.extend({
  model: EventsFollowModel,
  url: '/api/protected/eventsFollow',

  initialize() {
    this.fetch({ reset: true })
  },

  parse(response) {
    return response
  },
})

var eventsFollowCollection = new EventsFollowCollection()

// make it a singleton
module.exports = eventsFollowCollection
