window.jQuery = require('jquery')
const _ = require('lodash')
const $ = require('jquery')
const B = require('backbone')
B.$ = $

require('../jqplugins')
require('../helpers/handlebarsHelpers')

const messageBus = require('../models/MessageBus')

const mapContainers = require('../../config/mapper').containers
const mapRoutes = require('../../config/mapper').routes

let activeView = null
let container = {}
let userRights = 'public'
let isDetailViewInitialized = false
let detailView = null

let router
let setContainer

const RouterManager = B.Router.extend({
  initialize() {
    this.listenTo(messageBus, 'xhrError', this.xhrError)
  },

  routes: mapRoutes,

  events(actions) {
    goToView('events')
  },

  detail(id) {
    let { DetailView } = require('../../../public/js/views/DetailView')
    const detailViewObject = {
      el: container.detail,
      router,
      userRights,
      eventId: id,
    }
    if (isDetailViewInitialized) {
      detailView.undelegateEvents()
    }
    detailView = new DetailView(detailViewObject)
    isDetailViewInitialized = true
    goToView('detail')
  },

  home(page, id) {
    goToView('home', { page, id })
  },

  blog(id) {
    goToView('home', { page: 'blog' })
  },

  admin(id) {
    goToView('admin')
  },

  defaultRoute(actions) {
    goToView('home')
  },
})

router = module.exports = new RouterManager()

$('#nav-home').on('click', (event) => {
  event.preventDefault()
  router.navigate('home', { trigger: true })
})

$('#nav-events').on('click', (event) => {
  event.preventDefault()
  router.navigate('events', { trigger: true })
})

$('#nav-admin').on('click', (event) => {
  event.preventDefault()
  router.navigate('admin', { trigger: true })
})

$('#nav-title').on('click', (event) => {
  event.preventDefault()
  router.navigate('home', { trigger: true })
})

let homeView

// navigate to view
function goToView(id, options) {
  if (activeView !== id) {
    if (id === 'detail') {
      $('#detail').show()
    } else {
      $('#container-detail').empty()
      $('#detail').hide()
    }

    console.log("changing to view '%s'", id)
    if (id !== 'xhrerror') {
      const nav = document.querySelector('#mainNavbarId')
      nav.querySelector('.active').classList.remove('active')
      nav.querySelector(`#${id}`).classList.add('active')
    }
    activeView = id
    const keys = Object.keys(container)
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      container[key].style.display = 'none'
    }
    console.log(`Setting container ${id} to display: block`)
    container[id].style.display = 'block'

    if (id === 'admin' && document.querySelector('.askAdminChatBox-admin')) {
      document.querySelector('.askAdminChatBox-admin').scrollTop =
        document.querySelector('.askAdminChatBox-admin').scrollHeight
    }
  }

  if (id === 'home') {
    const page = options && options.page
    const id = options && options.id
    if (page) {
      homeView.openTab(page)

      if (page === 'blog') {
        if (id) {
          homeView.homeBlogCollectionView.renderItem(id)
        } else {
          // /home/blog should render main blog
          homeView.homeBlogCollectionView.renderMainBlog()
        }
      }
    } else {
      // this is the default view of /home
      homeView.homeBlogCollectionView.renderMainBlog()
    }
  }
}

var ViewManager = function ViewManager() {}

ViewManager.prototype.setUserRights = function setUserRights(userCheck) {
  userRights = userCheck
  setContainer()
}

setContainer = function setContainer() {
  container = {}

  Object.keys(mapContainers).forEach(
    (key) => (container[key] = document.querySelector(mapContainers[key])),
  )
}

ViewManager.prototype.renderViews = function renderViews() {
  // growl notification view
  const GrowlView = require('../../js/views/GrowlView')
  const growlView = new GrowlView()
  document.body.appendChild(growlView.render().el)

  // XHR error View
  const XhrErrorView = require('../../js/views/XhrErrorView')
  const xhrErrorView = new XhrErrorView({
    el: container.xhrerror,
  })
  xhrErrorView.render()

  const waitForRender = []
  const renderLater = []

  // Home View
  const { HomeView } = require('../../../public/js/views/HomeView')
  homeView = new HomeView({
    el: container.home,
    router,
  })

  waitForRender.push({ name: 'homeView', view: homeView })

  if (userRights === 'protected' || userRights === 'admin') {
    // NavigationUserView
    let { NavigationUserView } = require('../views/NavigationUserView')
    const navigationUserView = new NavigationUserView({
      el: document.querySelector('ul.nav.user'),
    })
    waitForRender.push({ name: 'navigationUserView', view: navigationUserView })
    renderLater.push(navigationUserView)

    // Events View
    let { EventsView } = require('../../../public/js/views/EventsView')
    const eventsView = new EventsView({
      el: container.events,
      router,
      accessLevel: userRights,
    })
    waitForRender.push({ name: 'eventsView', view: eventsView })
    renderLater.push(eventsView)

    if (userRights === 'admin') {
      // Admin View
      let { AdminView } = require('../../../public/js/views/AdminView')
      const adminView = new AdminView({
        el: container.admin,
        router,
      })
      waitForRender.push({ name: 'adminView', view: adminView })
      renderLater.push(adminView)
    }
  }

  const allRenders = waitForRender.map((view) =>
    new Promise((resolve) => view.view.on('didRender', _.once(resolve))).then(
      (x) => {
        console.log(`didRender for ${view.name}`)
        return x
      },
    ),
  )

  // Now that we have set up a promise to wait on the renders, start rendering.
  renderLater.forEach((view) => view.render())
  homeView.loadData()

  console.log(`waiting for ${allRenders.length} renders`)
  Promise.all(allRenders)
    .then(() => {
      console.log('history start')
      B.history.start({ pushState: true })
    })
    .catch((err) => console.error(err))
}

module.exports = new ViewManager()
