/*
	Displays the events objects in a table.
*/
const Backbone = require('backbone')

const eventsColumns =
  require('../../../common/config/constants').eventsFieldNames

const eventsModel = require('../models/EventsModel')
const eventsFilterModel = require('../models/EventsFilterModel')

exports.EventsCollectionView = Backbone.View.extend({
  template: require('../../templates/EventsCollectionView.hbs'),

  isProtected: false,

  // Collection containing events objects to be displayed
  eventsDataCollection: null,

  // Sort Id which was clicked
  sortIdClicked: null,

  dataPopulated: false,

  // Previous sort id which was clicked.
  // If the current sort id clicked matches previous one,
  // List needs to be sorted in descending order based on sort id
  previousSortIdClicked: null,

  orbits: [],

  initialize(options) {
    console.log('Initialized Events Collection View')

    this.router = options.router

    this.eventsDataCollection = eventsModel.getDisplayCollection()

    this.divHeight = options.divHeight - 10

    this.listenTo(this.eventsDataCollection, 'reset', () => {
      this.eventsDataCollectionJSON = this.eventsDataCollection.toJSON()
      this.dataPopulated = true
      this.render()
    })
  },

  events: {
    'click .eventsViewType': 'switchView',
    'click button.viewDetails': 'viewDetails',
    'click .sort': 'sort',
    'click .focusDot': 'viewDetails',
    'click .satOrbitPin': 'viewDetails',
    'click .events_tab': 'openTab',
  },

  render() {
    var markup = this.template({
      dataPopulated: this.dataPopulated,
      collection: this.eventsDataCollectionJSON,
      divHeight: this.divHeight - 55 + 'px',
      eventsColumns,
    })
    this.$el.html(markup)

    // If a column sorted, add drop up, drop down icon
    if (this.sortIdClicked !== null) {
      var span = document.createElement('span')
      if (this.sortIdClicked === this.previousSortIdClicked) {
        span.className = 'dropup'
      } else {
        span.className = 'dropDown'
      }
      var spanChild = document.createElement('span')
      spanChild.className = 'caret'
      span.appendChild(spanChild)
      this.el
        .querySelector('.addSortButton-' + this.sortIdClicked)
        .appendChild(span)
    }

    return this
  },

  sort(e) {
    console.log('Sort ', e)
    this.sortIdClicked = e.currentTarget.id

    // sort in descending if same field clicked twice
    if (this.sortIdClicked === this.previousSortIdClicked) {
      this.previousSortIdClicked = null
      eventsFilterModel.set('sort', 'desc|' + e.currentTarget.id)
    } else {
      this.previousSortIdClicked = this.sortIdClicked
      eventsFilterModel.set('sort', 'asc|' + e.currentTarget.id)
    }
  },

  viewDetails(e) {
    var eventId = e.currentTarget.id
    this.router.navigate('events/' + eventId, { trigger: true })
  },
})
