/*
	Displays the history of an object in a table.
*/
var Backbone = require('backbone')
var _ = require('lodash')
var $ = require('jquery')

var eventsColumns = require('../../../common/config/constants').eventsFieldNames

var DetailHistoryView = (exports.DetailHistoryView = Backbone.View.extend({
  template: require('../../templates/DetailHistoryView.hbs'),

  // Collection containing history to be displayed
  historyCollection: null,

  initialize(options) {
    console.log('Initialized Detail History View')

    this.historyCollection = options.collection

    this.divHeight = options.height
  },

  render() {
    var markup = this.template({
      collection: this.historyCollection,
      divHeight: this.divHeight - 110 + 'px',
      eventsColumns,
      object1CosparId:
        this.historyCollection[0][eventsColumns.OBJECT1_COSPAR_ID],
      object2CosparId:
        this.historyCollection[0][eventsColumns.OBJECT2_COSPAR_ID],
    })
    this.$el.html(markup)
    return this
  },
}))
