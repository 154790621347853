/*
	Admin View
*/

const Backbone = require('backbone')
const _ = require('lodash')
const $ = require('jquery')

const { UserEventItemView } = require('./UserEventItemView')

const adminUserFilterModel = require('../models/AdminUserFilterModel')
const adminEventFilterModel = require('../models/AdminEventFilterModel')
const adminModel = require('../../../public/js/models/AdminModel')
const SettingsCollection = require('../models/SettingsCollection')

const eventsColumns =
  require('../../../common/config/constants').eventsFieldNames
const Util = require('../../../common/js/helpers/Util.js')

exports.AdminView = Backbone.View.extend({
  template: require('../../templates/AdminView.hbs'),

  divheight: 0,

  // active email id index
  setEmailId: 0,

  // active event id index
  setEventId: 0,

  // render only if user and event lists are both set to true
  userListSet: false,

  eventListSet: false,

  userCollection: null,

  // Stores text put in filter of email and event id.
  emailSearchValue: '',

  eventSearchValue: '',

  // Auto focusses to text filter if search going on
  autoFocusField: null,

  initialize(options) {
    console.log('Initialized Admin View')

    this.router = options.router

    this.divHeight = this.$el.height()

    this.userCollection = adminModel.getDisplayUserCollection()
    this.eventCollection = adminModel.getDisplayEventCollection()

    this.listenTo(this.userCollection, 'reset', function (e) {
      this.userList = this.userCollection.toJSON()
      this.displayUserList = this.userList
      this.userListSet = true
      this.userDomainCheckBox = adminModel.getUserDomainCheckBoxList()
      this.render()
    })

    this.listenTo(this.eventCollection, 'reset', function (e) {
      // Add fields which is + of two objects in FDM
      let mergedItems = this.eventCollection.toJSON()

      this.eventCollectionJSON = Util.mergeExtraColumns(mergedItems)

      this.eventDetailList = _.uniqBy(
        this.eventCollectionJSON,
        function (item) {
          return item[eventsColumns.EVENT_ID]
        },
      )
      var genericObject = {}
      genericObject[eventsColumns.OBJECT_COSPAR_ID] = 'Generic'
      genericObject[eventsColumns.EVENT_ID] = '-1'
      this.eventDetailList.push(genericObject)

      // sort event list by fragmentation id
      this.displayEventDetailList = _.sortBy(
        this.eventDetailList,
        function (item) {
          return +item[eventsColumns.EVENT_ID]
        },
      )
      this.eventListSet = true
      this.render()
    })
  },

  events: {
    'click .usersEmailListItem': 'usersEmailListItem',
    'click .eventsListItem': 'eventsListItem',
    'input input.text': 'applyTextFilter',
    'click input[type=checkbox]': 'applyCheckListFilter',
  },

  // Render user settings, ask admin and event info
  renderUserEventItem() {
    if (this.el.querySelector('.selectItem')) {
      this.$el.find('.selectItem').remove()
    }

    const eventId = _.map(this.displayEventDetailList, eventsColumns.EVENT_ID)[
      this.setEventId
    ]
    const userId = _.map(this.displayUserList, 'email')[this.setEmailId]

    const settingsCollection = new SettingsCollection({
      eventId,
      userId,
    })

    this.listenTo(settingsCollection, 'sync', function () {
      this.userEventItemView = new UserEventItemView({
        router: this.router,
        eventId: _.map(this.displayEventDetailList, eventsColumns.EVENT_ID)[
          this.setEventId
        ],
        eventInfo: this.displayEventDetailList[this.setEventId],
        userInfo: this.displayUserList[this.setEmailId],
        userSettings: settingsCollection,
      })
      this.el
        .querySelector('.selectionItem')
        .appendChild(this.userEventItemView.render(this.divHeight).el)
    })
  },

  render() {
    let dataPopulated = false
    if (this.userListSet && this.eventListSet) {
      dataPopulated = true
    }
    const markup = this.template({
      eventList: this.displayEventDetailList,
      userList: _.map(this.displayUserList, 'email'),
      divHeight: this.divHeight - 40 + 'px',
      eventSearchValue: adminEventFilterModel.has(eventsColumns.EVENT_ID)
        ? adminEventFilterModel.get(eventsColumns.EVENT_ID).text
        : '',
      emailSearchValue: adminUserFilterModel.has('email')
        ? adminUserFilterModel.get('email').text
        : '',
      userDomainCheckBox: this.userDomainCheckBox,
      eventsColumns,
      dataPopulated,
    })
    this.$el.html(markup)
    if (dataPopulated === true) {
      this.$el.find('#usersEmailListItem-' + this.setEmailId).addClass('active')
      this.$el.find('#eventsListItem-' + this.setEventId).addClass('active')

      // Autofocus if last filter was text search
      if (this.autoFocusField !== null) {
        this.el.querySelector('#' + this.autoFocusField).focus()
        this.el.querySelector('#' + this.autoFocusField).value =
          this.el.querySelector('#' + this.autoFocusField).value
      }

      this.renderUserEventItem()
    }
    this.trigger('didRender')
    return this
  },

  usersEmailListItem(e) {
    this.$el
      .find('#usersEmailListItem-' + this.setEmailId)
      .removeClass('active')

    this.setEmailId = e.currentTarget.id.split('-')[1]
    this.$el.find('#usersEmailListItem-' + this.setEmailId).addClass('active')
    this.renderUserEventItem()
  },

  eventsListItem(e) {
    this.$el.find('#eventsListItem-' + this.setEventId).removeClass('active')

    this.setEventId = e.currentTarget.id.split('-')[1]
    this.$el.find('#eventsListItem-' + this.setEventId).addClass('active')
    this.renderUserEventItem()
  },

  applyTextFilter: _.debounce(function (e) {
    console.log('Text searched', e)
    this.autoFocusField = e.currentTarget.id
    if (e.currentTarget.id.split('-')[0] === 'user') {
      adminUserFilterModel.set(e.currentTarget.id.split('-')[1], {
        text: e.currentTarget.value,
      })
    } else {
      adminEventFilterModel.set(e.currentTarget.id.split('-')[1], {
        text: e.currentTarget.value,
      })
    }
  }, 500),

  applyCheckListFilter(e) {
    console.log('CheckList clicked', e)
    this.autoFocusField = null

    var inputGroup = $(e.target).parents('div.input-group')
    var inputValues = []
    inputGroup.find('.' + e.currentTarget.className).each(function (index) {
      var checked = $(this).prop('checked')
      if (!checked) {
        inputValues.push($(this).prop('value'))
      }
    })

    var formId = $(e.currentTarget)
      .parents('.' + e.currentTarget.className + 'Form')
      .prop('id')

    if (formId.split('-')[0] === 'user') {
      adminUserFilterModel.set(formId.split('-')[1], { checkbox: inputValues })
    } else {
      adminEventFilterModel.set(formId.split('-')[1], { checkbox: inputValues })
    }
  },
})
