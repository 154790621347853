var Backbone = require('backbone')
var $ = require('jquery')

module.exports = Backbone.View.extend({
  template: require('../../templates/XhrErrorTemplate.hbs'),
  initialize(inOptions) {
    this.el = inOptions.el
    this.error = {}
  },

  setError(error) {
    this.error = error
    this.render()
  },

  render() {
    var markup = this.template(this.error)
    this.$el.html(markup)
    return this
  },
})
