var doctype =
  '<?xml version="1.0" standalone="no"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">'

function styles(dom) {
  const cssLines = []
  for (const sheet of document.styleSheets) {
    let rules
    try {
      rules = sheet.cssRules
    } catch (err) {
      continue
    }

    for (const rule of rules) {
      if (rule.style === undefined) continue
      const elements = dom.querySelectorAll(rule.selectorText)
      if (elements.length > 0) {
        cssLines.push(`${rule.selectorText} { ${rule.style.cssText} }`)
      }
    }
  }


  const s = document.createElement('style')
  s.setAttribute('type', 'text/css')
  s.innerHTML = '<![CDATA[\n' + cssLines.join('\n') + '\n]]>'

  const defs = document.createElement('defs')
  defs.appendChild(s)
  return defs
}

function svgAsDataUri(el, scaleFactor) {
  scaleFactor = scaleFactor || 1

  var outer = document.createElement('div')
  var clone = el.cloneNode(true)

  var width = parseInt(
    clone.getAttribute('width') ||
      clone.style.width ||
      this.getComputedStyle(el).getPropertyValue('width'),
  )

  var height = parseInt(
    clone.getAttribute('height') ||
      clone.style.height ||
      this.getComputedStyle(el).getPropertyValue('height'),
  )

  var xmlns = 'http://www.w3.org/2000/xmlns/'

  clone.setAttribute('version', '1.1')
  clone.setAttributeNS(xmlns, 'xmlns', 'http://www.w3.org/2000/svg')
  clone.setAttributeNS(xmlns, 'xmlns:xlink', 'http://www.w3.org/1999/xlink')
  clone.setAttribute('width', width * scaleFactor)
  clone.setAttribute('height', height * scaleFactor)
  clone.setAttribute('viewBox', '0 0 ' + width + ' ' + height)

  outer.appendChild(clone)

  clone.insertBefore(styles(clone), clone.firstChild)

  var svg = doctype + outer.innerHTML
  var uri =
    'data:image/svg+xml;base64,' +
    window.btoa(unescape(encodeURIComponent(svg)))

  return uri
}

async function svgAsPng(el, scaleFactor) {
  const dataUri = svgAsDataUri(el, scaleFactor)
  const image = document.createElement('img')
  const canvas = document.createElement('canvas')

  const imageLoad = new Promise((resolve) => {
    image.onload = resolve
  })
  image.src = dataUri
  await imageLoad

  canvas.width = image.width
  canvas.height = image.height

  const context = canvas.getContext('2d')
  context.drawImage(image, 0, 0)

  return canvas.toDataURL('image/png')
}

module.exports = {
  svgAsPng,
  svgAsDataUri,
}

