/*
	View displays the item selected from the files menu
*/

var Backbone = require('backbone')
var _ = require('lodash')
var $ = require('jquery')
var moment = require('moment')

var svgConverter = require('../../../common/js/helpers/svg2dataUri')

let { DetailPlotView } = require('./DetailPlotView')
let { DetailHistoryView } = require('./DetailHistoryView')

var eventsColumns = require('../../../common/config/constants').eventsFieldNames

var DetailFilesItemView = (exports.DetailFilesItemView = Backbone.View.extend({
  template: require('../../templates/DetailFilesItemView.hbs'),

  subTemplate: require('../../templates/DetailFilesEachItemView.hbs'),

  divDisplayedItems: {},

  isSingleView: true,

  elementAtIndexZero: null,

  initialize(options) {
    console.log('Initialized Details Files Item View')

    this.detailFilesItemModel = options.detailFilesItemModel

    this.detailFilesItemModel.set('view', 'single')

    this.divHeight = options.divHeight

    this.eventId = options.eventId

    this.detailEventCollection = options.detailEventCollection

    this.detailSimulationCollection = options.detailSimulationCollection

    // if new files to be displayed or chnage in view (single to multiple or vice-versa)
    this.listenTo(
      this.detailFilesItemModel,
      'change:fileIds change:view',
      function () {
        this.isSingleView =
          this.detailFilesItemModel.get('view') === 'single' ? true : false
        if (this.isSingleView) {
          if (this.detailFilesItemModel.has('fileIds')) {
            this.render(true)
          } else {
            this.render(false)
          }
        } else {
          if (this.detailFilesItemModel.has('fileIds')) {
            for (
              var i = 0;
              i < this.detailFilesItemModel.get('fileIds').length;
              i++
            ) {
              this.populateDisplayItems(
                this.detailFilesItemModel.get('fileIds')[i],
                i,
              )
            }
            this.render(true)
          } else {
            this.render(false)
          }
        }
      },
    )
  },

  events: {
    'click .changeDate': 'changeDate',
    'click .itemChange': 'itemChange',
    'click .itemRemove': 'itemRemove',
    'click .viewType': 'switchView',
    'click .fullScreen': 'fullScreen',
    'submit .altitudeChange': 'changeAltitude',
  },

  // make sub div to display one item
  async makeSubDiv(id) {
    var fileIds = this.detailFilesItemModel.get('fileIds')
    var isFirstItem = fileIds[id].indexItemDisplayed === 0 ? true : false
    var isLastItem =
      fileIds[id].indexItemDisplayed === fileIds[id].itemList.length - 1
        ? true
        : false
    var panelFooter = false
    var dateFilter = false
    var searchFilter = false
    var searchLabel = ''
    var searchNumber = false
    var searchMin = 0
    var searchMax = 0
    var searchStep = 0
    var searchValue = ''
    var searchClass = ''
    var panelBodyHeight =
      this.isSingleView || Object.keys(fileIds).length <= 2
        ? this.divHeight - 150 + 'px'
        : this.divHeight / 2 - 150 + 'px'

    var item
    var itemName

    if (fileIds[id].itemDisplayed.extension === 'plot') {
      let itemHeight =
        this.isSingleView || Object.keys(fileIds).length <= 2
          ? this.divHeight - 180
          : this.divHeight / 2 - 180
      var itemWidth = this.isSingleView
        ? this.$el.width() - 30
        : this.$el.width() / 2 - 60

      this.detailPlotView = new DetailPlotView({
        height: itemHeight,
        width: itemWidth,
      })

      const objectCosparId = fileIds[id].itemDisplayed.subType
      const simulationData =
        this.detailSimulationCollection[objectCosparId].toJSON()

      const maxRisk1Cm = _.maxBy(simulationData, 'risk1cm').risk1cm
      const maxRisk10Cm = _.maxBy(simulationData, 'risk10cm').risk10cm
      const maxRiskPercent = _.max([maxRisk1Cm, maxRisk10Cm]) * 100

      const maxAltitude = _.maxBy(simulationData, 'altitude').altitude
      const minAltitude = _.minBy(simulationData, 'altitude').altitude

      var objectName =
        objectCosparId ===
        this.detailEventCollection[0][eventsColumns.OBJECT_COSPAR_ID]
          ? this.detailEventCollection[0][eventsColumns.NAME]
          : objectCosparId ===
            this.detailEventCollection[0][eventsColumns.OBJECT1_COSPAR_ID]
          ? this.detailEventCollection[0][eventsColumns.OBJECT1_NAME]
          : this.detailEventCollection[0][eventsColumns.OBJECT2_NAME]

      if (objectName === null) {
        objectName = eventsColumns.UNDEFINED
      }

      switch (fileIds[id].itemDisplayed.name) {
        case 'Risk vs Altitude':
          var simulationAltitudeColelction = _.sortBy(
            _.filter(simulationData, function (o) {
              if (
                moment.unix(o.epoch / 1000).format('YYYYMMDDHHmmss') ===
                fileIds[id].itemDisplayed.date
              ) {
                return true
              }
            }),
            'altitude',
          )

          this.detailPlot = this.detailPlotView.renderRiskVsAltitude(
            simulationAltitudeColelction,
            maxRiskPercent,
            maxAltitude,
            minAltitude,
            objectName,
            objectCosparId,
          )
          panelFooter = true
          dateFilter = true
          searchFilter = false
          break
        case 'Risk vs Time':
          var altitude = fileIds[id].hasOwnProperty('altitude')
            ? fileIds[id].altitude
            : 800

          var simulationTimeCollection = this.getSimulatedTimeCollection(
            simulationData,
            altitude,
          )

          this.detailPlot = this.detailPlotView.renderRiskVsTime(
            simulationTimeCollection,
            maxRiskPercent,
            objectName,
            objectCosparId,
          )
          panelFooter = true
          dateFilter = false
          searchFilter = true
          searchLabel = 'Enter Altitude(km): '
          searchNumber = true
          searchMax = maxAltitude
          searchMin = minAltitude
          searchStep = 0.5
          searchValue = altitude
          searchClass = 'altitudeChange'
          break
      }

      item = await svgConverter.svgAsPng(
        this.detailPlot.el.querySelector('svg'),
      )
      itemName = fileIds[id].itemDisplayed.name + '.png'
    } else if (fileIds[id].itemDisplayed.extension === 'table') {
      let itemHeight =
        this.isSingleView || Object.keys(fileIds).length <= 2
          ? this.divHeight - 180
          : this.divHeight / 2 - 180

      this.detailHistoryView = new DetailHistoryView({
        collection: _.orderBy(
          this.detailEventCollection,
          eventsColumns.CREATION_DATE,
          'desc',
        ),
        height: itemHeight,
      })

      this.detailHistory = this.detailHistoryView.render()
      panelFooter = false
      panelBodyHeight =
        this.isSingleView || Object.keys(fileIds).length <= 2
          ? this.divHeight - 95 + 'px'
          : this.divHeight / 2 - 95 + 'px'
    } else {
      item =
        '../../../files/' + this.eventId + '/' + fileIds[id].itemDisplayed.file
      itemName =
        fileIds[id].itemDisplayed.name +
        '.' +
        fileIds[id].itemDisplayed.extension
      panelFooter = true
      dateFilter = true
      searchFilter = false
    }

    var displayItem = this.subTemplate({
      item,
      itemName,
      downloadIconDisplay:
        fileIds[id].itemDisplayed.extension === 'table' ? false : true,
      itemIndexDisplayed: fileIds[id].indexItemDisplayed + 1,
      totalItems: fileIds[id].itemList.length,
      isFirstItem,
      isLastItem,
      index: id,
      panelHeading:
        fileIds[id].itemDisplayed.name +
        ' (' +
        fileIds[id].itemDisplayed.type +
        ')',
      isSingleView: this.isSingleView,
      panelBodyHeight,
      includeDates: fileIds[id].includeDates,
      displayDate: moment
        .utc(fileIds[id].itemDisplayed.date, 'YYYYMMDDHHmmss')
        .format('DD-MMM-YYYY HH:mm:ss'),
      datesDropDownDisabled:
        fileIds[id].includeDates.length <= 1 ? true : false,
      panelFooter,
      dateFilter,
      searchFilter,
      searchLabel,
      searchNumber,
      searchMax,
      searchMin,
      searchStep,
      searchValue,
      searchClass,
    })

    var container = document.createDocumentFragment()
    var div = document.createElement('div')
    div.innerHTML = displayItem
    container.appendChild(div.childNodes[0])

    this.divDisplayedItems[id] = container
  },

  // make content to be displayed in the item
  makeDivDetailItem(id) {
    var fileIds = this.detailFilesItemModel.get('fileIds')
    var detailItem = document.createDocumentFragment()

    let itemHeight =
      this.isSingleView || Object.keys(fileIds).length <= 2
        ? this.divHeight - 180
        : this.divHeight / 2 - 180

    switch (fileIds[id].itemDisplayed.extension) {
      case 'plot':
        detailItem.appendChild(this.detailPlot.el)
        break
      case 'table':
        detailItem.appendChild(this.detailHistory.el)
        break
      case 'jpg':
      case 'jpeg':
      case 'png':
        var img = document.createElement('img')
        img.src =
          '../../../files/' +
          this.eventId +
          '/' +
          fileIds[id].itemDisplayed.file
        img.style.maxHeight = itemHeight + 'px'
        detailItem.appendChild(img)
        break
      default:
        var embed = document.createElement('embed')
        embed.src =
          '../../../files/' +
          this.eventId +
          '/' +
          fileIds[id].itemDisplayed.file
        embed.style.width = '100%'
        embed.style.height = itemHeight + 'px'
        detailItem.appendChild(embed)
        break
    }

    return detailItem
  },

  // render item
  async renderItem(id, self) {
    await this.makeSubDiv(id)
    this.el.querySelector('#item-' + id).appendChild(this.divDisplayedItems[id])
    this.el
      .querySelector('#detailItem-' + id)
      .appendChild(this.makeDivDetailItem(id))
  },

  // render full item view (can contain multiple items in multiple view)
  render(filesLoaded) {
    if (filesLoaded) {
      // if files collection is already fetched
      var fileIds = this.detailFilesItemModel.get('fileIds')

      // For single view
      var displayedItemsIndices = ['0']
      var size = '12'

      // For multiple view
      if (!this.isSingleView) {
        displayedItemsIndices = Object.getOwnPropertyNames(fileIds)
        size = '6'
      }

      // For fullscreen single view
      if (this.detailFilesItemModel.has('fullScreen')) {
        displayedItemsIndices = [this.detailFilesItemModel.get('fullScreen')]
      }

      let markup = this.template({
        filesLoaded,
        size,
        displayItems: displayedItemsIndices,
        isSingleView: this.isSingleView,
        divHeight: this.divHeight + 'px',
      })

      this.$el.html(markup)

      if (this.detailFilesItemModel.has('fullScreen')) {
        this.renderItem(this.detailFilesItemModel.get('fullScreen'))
      } else if (this.isSingleView) {
        this.renderItem(0)
      } else {
        _.keys(fileIds).forEach((key) => {
          this.renderItem(key)
        })
      }
    } else {
      // if files collection not fetched yet, display blank view
      let markup = this.template({ filesLoaded })
      this.$el.html(markup)
      if (this.isSingleView) {
        this.$el.find('#single').addClass('active')
        this.$el.find('#multiple').removeClass('active')
      } else {
        this.$el.find('#multiple').addClass('active')
        this.$el.find('#single').removeClass('active')
      }
    }

    return this
  },

  changeDate(e) {
    console.log('Event Fired', e)
    var elementId = e.currentTarget.id.split('|')
    var eDate = elementId[0]
    var id = elementId[1]
    var fileIds = this.detailFilesItemModel.get('fileIds')
    if (
      eDate !==
      moment
        .utc(fileIds[id].itemDisplayed.date, 'YYYYMMDDHHmmss')
        .format('DD-MMM-YYYY HH:mm:ss')
    ) {
      fileIds[id].itemDisplayed = _.first(
        _.filter(fileIds[id].itemList, {
          date: moment
            .utc(eDate, 'DD-MMM-YYYY HH:mm:ss')
            .format('YYYYMMDDHHmmss'),
        }),
      )
      fileIds[id].indexItemDisplayed = fileIds[id].itemList.indexOf(
        fileIds[id].itemDisplayed,
      )
      this.detailFilesItemModel.set('fileIds', fileIds)
      this.detailFilesItemModel.trigger('change:fileIds')
    }
  },

  itemChange(e) {
    console.log('Item Event Fired', e)
    var id = e.currentTarget.id.split('-')
    var fileIds = this.detailFilesItemModel.get('fileIds')
    switch (id[0]) {
      case 'firstImg':
        fileIds[id[1]].indexItemDisplayed = 0
        break
      case 'prevImg':
        fileIds[id[1]].indexItemDisplayed =
          fileIds[id[1]].indexItemDisplayed - 1
        break
      case 'nextImg':
        fileIds[id[1]].indexItemDisplayed =
          fileIds[id[1]].indexItemDisplayed + 1
        break
      case 'lastImg':
        fileIds[id[1]].indexItemDisplayed = fileIds[id[1]].itemList.length - 1
        break
    }

    fileIds[id[1]].itemDisplayed =
      fileIds[id[1]].itemList[fileIds[id[1]].indexItemDisplayed]
    this.detailFilesItemModel.set('fileIds', fileIds)
    this.detailFilesItemModel.trigger('change:fileIds')
  },

  itemRemove(e) {
    console.log('Item Remove', e)
    var id = e.currentTarget.id.split('-')
    var fileIds = this.detailFilesItemModel.get('fileIds')
    delete fileIds[id[1]]
    this.detailFilesItemModel.set('fileIds', fileIds)
    this.detailFilesItemModel.trigger('change:fileIds')
  },

  switchView(e) {
    console.log('View Id', e)
    this.detailFilesItemModel.unset('fullScreen')
    this.detailFilesItemModel.set('view', e.currentTarget.id)
  },

  fullScreen(e) {
    console.log('View Id', e)
    var id = e.currentTarget.id.split('-')
    this.detailFilesItemModel.set('fullScreen', id[1])
    this.detailFilesItemModel.set('view', 'single')
  },

  changeAltitude(e) {
    e.preventDefault()
    e.stopPropagation()
    var elementId = e.currentTarget.id.split('-')[1]
    var fileIds = this.detailFilesItemModel.get('fileIds')
    fileIds[elementId].altitude = this.el.querySelector(
      '#altitudeChange-' + elementId,
    ).value
    this.detailFilesItemModel.set('fileIds', fileIds)
    this.detailFilesItemModel.trigger('change:fileIds')
  },

  getSimulatedTimeCollection(collection, altitude) {
    var sortCollectionByAltitude = _.sortBy(collection, 'altitude')

    var returnCollection = []

    altitude = +altitude

    if (
      _.uniq(_.map(sortCollectionByAltitude, 'altitude')).indexOf(altitude) > -1
    ) {
      // if altitude already found in collection, return it.
      returnCollection = _.sortBy(
        _.filter(sortCollectionByAltitude, function (o) {
          if (o.altitude === altitude) {
            return true
          }
        }),
        'epoch',
      )
    } else {
      // if altitude not found
      var allAltitudes = _.uniq(_.map(sortCollectionByAltitude, 'altitude'))

      // get the altitude in collection which is just smaller than the specified altitude
      // and the altitude that is just larger than the specified altitude
      // to calculate points between the smaller and the larger altitude
      var smallAltitude = allAltitudes[0]
      var largeAltitude = allAltitudes[allAltitudes.length - 1]
      for (var i = 1; i < allAltitudes.length; i++) {
        if (allAltitudes[i] < altitude) {
          smallAltitude = allAltitudes[i]
        } else {
          largeAltitude = allAltitudes[i]
          break
        }
      }
      var largeSmallDiff = largeAltitude - smallAltitude

      // difference between specified altitude and the small altitude
      var altitudeSmallDiff = altitude - smallAltitude

      // get objects from the collection equal to small altitude
      var smallCollection = _.filter(sortCollectionByAltitude, function (o) {
        if (o.altitude === smallAltitude) {
          return true
        }
      })

      // get objects from the collection equal to large altitude
      var largeCollection = _.filter(sortCollectionByAltitude, function (o) {
        if (o.altitude === largeAltitude) {
          return true
        }
      })

      // calculate risks for the specified altitude and push it to return collection
      for (i = 0; i < smallCollection.length; i++) {
        var simObj = {}
        var epoch = smallCollection[i].epoch
        if (_.map(largeCollection, 'epoch').includes(epoch)) {
          var largeObj = _.find(largeCollection, function (o) {
            return o.epoch === epoch
          })
          simObj.epoch = epoch
          var largeSmallRisk1CmDiff =
            largeObj.risk1cm - smallCollection[i].risk1cm
          simObj.risk1cm =
            smallCollection[i].risk1cm +
            (largeSmallRisk1CmDiff / largeSmallDiff) * altitudeSmallDiff
          var largeSmallRisk10CmDiff =
            largeObj.risk10cm - smallCollection[i].risk10cm
          simObj.risk10cm =
            smallCollection[i].risk10cm +
            (largeSmallRisk10CmDiff / largeSmallDiff) * altitudeSmallDiff
          returnCollection.push(simObj)
        }
      }
      returnCollection = _.sortBy(returnCollection, 'epoch')
    }

    return returnCollection
  },
}))
