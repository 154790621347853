/*
	Global Events Objects View which populates the required sub-events views
	e.g list of events objects, filtering, ask admin.
*/
var Backbone = require('backbone')
var _ = require('lodash')
var $ = require('jquery')

var eventsFollowCollection = require('../models/EventsFollowCollection')

let { EventsFilterView } = require('./EventsFilterView')
let { EventsCollectionView } = require('./EventsCollectionView')

let { AskAdminView } = require('../../../common/js/views/AskAdminView')

var adminModel = require('../../../public/js/models/AdminModel')

var EventsView = (exports.EventsView = Backbone.View.extend({
  template: require('../../templates/EventsView.hbs'),

  askAdminWindowOpened: false,

  initialize(options) {
    console.log('Initialized Events View')

    this.router = options.router

    this.isAdmin = options.accessLevel === 'admin' ? true : false

    this.askAdminView = new AskAdminView({
      eventId: null,
      emailUser: null,
      isAdminUser: options.accessLevel === 'admin' ? true : false,
      view: 'events',
    })

    this.listenTo(eventsFollowCollection, 'sync add', function (e) {
      this.eventsCollectionView = new EventsCollectionView({
        accessLevel: options.accessLevel,
        router: this.router,
        divHeight: this.$el.parent().height(),
      })
      this.renderCollection()
    })

    this.filterView = new EventsFilterView()
  },

  events: {
    'click .askAdminPanelHeading-events': 'eventsAskAdminPanelHeading',
  },

  renderFilter() {
    //this.listenTo(
    //	eventsRestrictedCollection,
    //	"sync",
    //	function(e) {
    this.el
      .querySelector('.eventsCollectionFilter')
      .appendChild(this.filterView.render().el)
    //	}
    //);
    return this
  },

  renderAskAdmin() {
    if (this.el.querySelector('.askAdminWindow-events')) {
      this.$el.find('.askAdminWindow-events').remove()
    }

    this.el
      .querySelector('.eventsAskAdminView')
      .appendChild(this.askAdminView.render(this.askAdminWindowOpened).el)
  },

  renderCollection() {
    if (this.el.querySelector('.eventscollection')) {
      this.$el.find('.eventsCollection').remove()
    }
    this.el
      .querySelector('.eventsCollectionView')
      .appendChild(this.eventsCollectionView.render().el)
    return this
  },

  render() {
    this.filterDivHeight = this.$el.parent().height() - 90
    var markup = this.template({
      filterDivHeight: this.filterDivHeight + 'px',
    })
    this.$el.html(markup)
    this.renderFilter()
    this.renderAskAdmin()
    this.trigger('didRender')
    return this
  },

  eventsAskAdminPanelHeading(e) {
    if (this.el.querySelector('.expandEventsAskAdmin')) {
      // if ask admin window closed, open it.
      // change the icon to opened in header
      // reduce filter div height
      this.$el.find('.expandEventsAskAdmin').remove()
      if (this.el.querySelector('.newMessages-events') && !this.isAdmin) {
        this.askAdminView.updateNewMessageStatus()
        this.el.querySelector('.newMessages-events').remove()
      }
      this.$el.find('#panelBodyAskAdmin-events').collapse('show')
      this.filterDivHeight = this.filterDivHeight - 335
      this.el.querySelector('.eventsFilterMenu').style.height =
        this.filterDivHeight + 'px'
      this.addPanelButton(
        'glyphicon glyphicon-triangle-top collapseEventsAskAdmin',
        '.askAdminPanelExpand-events',
      )
      if (this.el.querySelector('.askAdminChatBox-events')) {
        this.el.querySelector('.askAdminChatBox-events').scrollTop =
          this.el.querySelector('.askAdminChatBox-events').scrollHeight
      }
      this.askAdminWindowOpened = true
    } else {
      // if ask admin window opened, close it.
      // change the icon to closed in header
      // increase filter div height
      this.$el.find('.collapseEventsAskAdmin').remove()
      this.$el.find('#panelBodyAskAdmin-events').collapse('hide')
      this.filterDivHeight = this.filterDivHeight + 335
      this.el.querySelector('.eventsFilterMenu').style.height =
        this.filterDivHeight + 'px'
      this.addPanelButton(
        'glyphicon glyphicon-triangle-right expandEventsAskAdmin',
        '.askAdminPanelExpand-events',
      )
      this.askAdminWindowOpened = false
    }
  },

  addPanelButton(spanClass, appendClass) {
    var span = document.createElement('span')
    span.className += spanClass

    this.el.querySelector(appendClass).appendChild(span)
  },
}))
