// Util
module.exports = {
  mergeExtraColumns(mergedItems) {
    var _ = require('lodash')
    var eventsColumns =
      require('../../../common/config/constants').eventsFieldNames

    // Add fields which is + of two objects in FDM
    mergedItems = _.map(mergedItems, function (element) {
      var newFields = {}

      if (
        element[eventsColumns.OBJECT2_NAME] !== null &&
        element[eventsColumns.OBJECT2_NAME] !== '' &&
        element[eventsColumns.OBJECT2_COSPAR_ID] !== null &&
        element[eventsColumns.OBJECT2_COSPAR_ID] !== ''
      ) {
        newFields[eventsColumns.NAME] =
          element[eventsColumns.OBJECT1_NAME] +
          eventsColumns.OBJECT1_OBJECT2_JOINER +
          element[eventsColumns.OBJECT2_NAME]
        newFields[eventsColumns.OBJECT_COSPAR_ID] =
          element[eventsColumns.OBJECT1_COSPAR_ID] +
          eventsColumns.OBJECT1_OBJECT2_JOINER +
          element[eventsColumns.OBJECT2_COSPAR_ID]
      } else if (
        (element[eventsColumns.OBJECT2_NAME] === null ||
          element[eventsColumns.OBJECT2_NAME] === '') &&
        (element[eventsColumns.OBJECT2_COSPAR_ID] === null ||
          element[eventsColumns.OBJECT2_COSPAR_ID] === '')
      ) {
        newFields[eventsColumns.NAME] = element[eventsColumns.OBJECT1_NAME]
        newFields[eventsColumns.OBJECT_COSPAR_ID] =
          element[eventsColumns.OBJECT1_COSPAR_ID]
      } else {
        if (
          element[eventsColumns.OBJECT2_NAME] !== null &&
          element[eventsColumns.OBJECT2_NAME] !== ''
        ) {
          newFields[eventsColumns.NAME] =
            element[eventsColumns.OBJECT1_NAME] +
            eventsColumns.OBJECT1_OBJECT2_JOINER +
            element[eventsColumns.OBJECT2_NAME]
          newFields[eventsColumns.OBJECT_COSPAR_ID] =
            element[eventsColumns.OBJECT1_COSPAR_ID] +
            eventsColumns.OBJECT1_OBJECT2_JOINER +
            eventsColumns.UNDEFINED
        } else if (
          element[eventsColumns.OBJECT2_COSPAR_ID] !== null &&
          element[eventsColumns.OBJECT2_COSPAR_ID] !== ''
        ) {
          newFields[eventsColumns.NAME] =
            element[eventsColumns.OBJECT1_NAME] +
            eventsColumns.OBJECT1_OBJECT2_JOINER +
            eventsColumns.UNDEFINED
          newFields[eventsColumns.OBJECT_COSPAR_ID] =
            element[eventsColumns.OBJECT1_COSPAR_ID] +
            eventsColumns.OBJECT1_OBJECT2_JOINER +
            element[eventsColumns.OBJECT2_COSPAR_ID]
        }
      }

      if (
        !element[eventsColumns.OBJECT1_CATALOGUED] &&
        !element[eventsColumns.OBJECT2_CATALOGUED]
      ) {
        newFields[eventsColumns.CATALOGUED] = null
      } else {
        newFields[eventsColumns.CATALOGUED] =
          element[eventsColumns.OBJECT1_CATALOGUED] +
          element[eventsColumns.OBJECT2_CATALOGUED]
      }
      if (
        !element[eventsColumns.OBJECT1_CATALOGUED_ON_ORBIT] &&
        !element[eventsColumns.OBJECT2_CATALOGUED_ON_ORBIT]
      ) {
        newFields[eventsColumns.CATALOGUED_ON_ORBIT] = null
      } else {
        newFields[eventsColumns.CATALOGUED_ON_ORBIT] =
          element[eventsColumns.OBJECT1_CATALOGUED_ON_ORBIT] +
          element[eventsColumns.OBJECT2_CATALOGUED_ON_ORBIT]
      }
      if (
        !element[eventsColumns.OBJECT1_1CM] &&
        !element[eventsColumns.OBJECT2_1CM]
      ) {
        newFields[eventsColumns.OBJECT_1CM] = null
      } else {
        newFields[eventsColumns.OBJECT_1CM] =
          element[eventsColumns.OBJECT1_1CM] +
          element[eventsColumns.OBJECT2_1CM]
      }
      if (
        !element[eventsColumns.OBJECT1_10CM] &&
        !element[eventsColumns.OBJECT2_10CM]
      ) {
        newFields[eventsColumns.OBJECT_10CM] = null
      } else {
        newFields[eventsColumns.OBJECT_10CM] =
          element[eventsColumns.OBJECT1_10CM] +
          element[eventsColumns.OBJECT2_10CM]
      }
      return _.assign(element, newFields)
    })

    return mergedItems
  },
}
