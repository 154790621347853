const $ = require('jquery')
const viewManager = require('../../common/js/managers/ViewManager')

// The server used to send three different copies of app.js to bootstrap
// the ViewManager with the correct userRights setting.
// Instead, we now store the information in the index.html generated from
// a template, and then read it here.
const userRightsData = $('#user-rights').data()

let userRights

if (userRightsData.isAdmin) {
  userRights = 'admin'
} else if (userRightsData.isPublic) {
  userRights = 'public'
} else {
  userRights = 'protected'
}

viewManager.setUserRights(userRights)
viewManager.renderViews()
