/*
	Model to store admin user filter attributes.
*/
var Backbone = require('backbone')

var AdminUserFilterModel = Backbone.Model.extend({})

var adminUserFilterModel = new AdminUserFilterModel()

// make it a singleton
module.exports = adminUserFilterModel
