module.exports={
  "containers": {
    "home": "#container-home",
    "detail": "#container-detail",
    "events": "#container-events",
    "admin": "#container-admin"
  },

  "routes": {
    "events/:id": "detail",
    "home(/:page)(/:id)": "home",
    "events": "events",
    "admin": "admin",
    "*actions": "defaultRoute"
  }
}
