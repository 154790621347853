var Backbone = require('backbone')

var UserSettings = Backbone.Model.extend({
  url: '/api/protected/settings',

  initialize() {
    this.fetch({
      reset: true,
    })
  },
})

var userSettings = new UserSettings()

// make it a singleton
module.exports = userSettings
