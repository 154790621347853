/*
	View to display list of users who have sent new messages in events and detail page.
*/
var Backbone = require('backbone')
var _ = require('lodash')
var $ = require('jquery')
var moment = require('moment')

var AskAdminUserCollection = require('../models/AskAdminUserCollection')

let {
  AskAdminMessagesView,
} = require('../../../common/js/views/AskAdminMessagesView')
var AskAdminMessagesCollection = require('../../../common/js/models/AskAdminMessagesCollection')

var AskAdminUserListView = (exports.AskAdminUserListView = Backbone.View.extend(
  {
    template: require('../../templates/AskAdminUserList.hbs'),

    initialize(options) {
      console.log('Initialized Ask Admin User List View')

      this.eventId = options.eventId
      this.view = options.view
    },

    render(collection) {
      var markup

      markup = this.template({
        collection: collection.toJSON(),
        collectionLength: collection.toJSON().length,
        view: this.view,
      })
      this.$el.html(markup)

      return this
    },
  },
))
