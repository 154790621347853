// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"col-lg-6\">\n      <div class=\"row\">\n        <div class=\"panel panel-info\">\n          <div class=\"panel-heading selectUserInfo\">\n            <div class=\"panel-title\">\n              <span class=\"glyphicon glyphicon-user\" />\n              User Settings\n            </div>\n          </div>\n          <div class=\"scroll-y-auto list-group\" style=\"height:"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"divHeightUserPanelBody") || (depth0 != null ? lookupProperty(depth0,"divHeightUserPanelBody") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"divHeightUserPanelBody","hash":{},"data":data,"loc":{"start":{"line":12,"column":62},"end":{"line":12,"column":88}}}) : helper)))
    + ";\">\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"userSettings") : depth0),{"name":"with","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":12},"end":{"line":40,"column":21}}})) != null ? stack1 : "")
    + "          </div>\n          <div class=\"panel-footer\">\n            <div class=\"pull-right\" role=\"group\">\n              <button class=\"btn btn-primary saveUserSettings\" style=\"padding-top: 8px; padding-bottom: 8px\" title=\"Save\">\n                <span class=\"glyphicon glyphicon-floppy-disk\"/>\n                Save\n              </button>\n            </div>\n            <div class=\"clearfix\"></div>\n          </div>\n        </div>\n        <div class=\"selectionMessageBox\" />\n      </div>\n    </div>\n    <div class=\"col-lg-6\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEventInfoDisplay") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":6},"end":{"line":144,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"isUserSettingsGeneric") : depths[1]),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.program(6, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":14,"column":14},"end":{"line":39,"column":21}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"list-group-item\">\n                  <div class=\"row\" style=\"padding-left:20px\">\n                    <small class=\"text-muted\">\n                      Frequency of Email on Followed Events\n                    </small>\n                    <div class=\"input-group\">\n                      <input type=\"radio\" name=\"frequencyEmail\" value=\"Daily\" id=\"frequencyEmailDaily\" "
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"frequencyEmail") : depth0),(depths[1] != null ? lookupProperty(depths[1],"daily") : depths[1]),{"name":"ifCond","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":103},"end":{"line":21,"column":158}}})) != null ? stack1 : "")
    + "><strong> Daily </strong></input>\n                      <input type=\"radio\" name=\"frequencyEmail\" value=\"Weekly\" id=\"frequencyEmailWeekly\" "
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"frequencyEmail") : depth0),(depths[1] != null ? lookupProperty(depths[1],"weekly") : depths[1]),{"name":"ifCond","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":105},"end":{"line":22,"column":161}}})) != null ? stack1 : "")
    + "><strong> Weekly </strong></input>\n                      <input type=\"radio\" name=\"frequencyEmail\" value=\"Never\" id=\"frequencyEmailNever\" "
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"frequencyEmail") : depth0),(depths[1] != null ? lookupProperty(depths[1],"never") : depths[1]),{"name":"ifCond","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":103},"end":{"line":23,"column":158}}})) != null ? stack1 : "")
    + "><strong> Never </strong></input>\n                    </div>\n                  </div>\n                </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return " checked ";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"list-group-item\">\n                  <div class=\"row\" style=\"padding-left:20px\">\n                    <small class=\"text-muted\">\n                      Event Followed\n                    </small>\n                    <div class=\"input-group\">\n                      <input type=\"radio\" name=\"eventFollowed\" value=\"True\" id=\"eventFollowedTrue\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isFollowed") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":99},"end":{"line":34,"column":133}}})) != null ? stack1 : "")
    + "><strong> True </strong></input>\n                      <input type=\"radio\" name=\"eventFollowed\" value=\"False\" id=\"eventFollowedFalse\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isFollowed") : depth0),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":101},"end":{"line":35,"column":143}}})) != null ? stack1 : "")
    + "><strong> False </strong></input>\n                    </div>\n                  </div>\n                </div>\n";
},"8":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"panel panel-info\">\n          <div class=\"panel-heading selectEventInfoHeading\">\n            <div class=\"panel-title\">\n              <span class=\"glyphicon glyphicon-asterisk\" />\n              Event Information\n            </div>\n          </div>\n          <div class=\"scroll-y-auto list-group\" style=\"height:"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"divHeightEventPanelBody") || (depth0 != null ? lookupProperty(depth0,"divHeightEventPanelBody") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"divHeightEventPanelBody","hash":{},"data":data,"loc":{"start":{"line":64,"column":62},"end":{"line":64,"column":89}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"eventsColumns") : depth0),{"name":"with","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":12},"end":{"line":132,"column":21}}})) != null ? stack1 : "")
    + "          </div>\n          <div class=\"panel-footer\">\n            <div class=\"pull-right\" role=\"group\">\n              <button class=\"btn btn-primary eventDetails\" style=\"padding-top: 8px; padding-bottom: 8px\" title=\"View Event Details\">\n                <span class=\"glyphicon glyphicon-new-window\"/>\n                Event Details\n              </button>\n            </div>\n            <div class=\"clearfix\"></div>\n          </div>\n        </div>\n";
},"9":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"eventInfo") : depths[1]),{"name":"with","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":14},"end":{"line":131,"column":23}}})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"list-group-item\">\n                  <div class=\"row\" style=\"padding-left:20px\">\n                    <small class=\"text-muted\">\n                      Name\n                    </small>\n                    <p><strong>"
    + alias2(lookupProperty(helpers,"lookup").call(alias1,depth0,(depths[1] != null ? lookupProperty(depths[1],"NAME") : depths[1]),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":72,"column":31},"end":{"line":72,"column":54}}}))
    + "</strong></p>\n                  </div>\n                </div>\n                <div class=\"list-group-item\">\n                  <div class=\"row\" style=\"padding-left:20px\">\n                    <small class=\"text-muted\">\n                      Event Date\n                    </small>\n                    <p><strong>"
    + alias2((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias3).call(alias1,"formatDate","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"EVENT_DATE") : depths[1]),{"name":"chain","hash":{},"data":data,"loc":{"start":{"line":80,"column":31},"end":{"line":80,"column":81}}}))
    + "</strong></p>\n                  </div>\n                </div>\n                <div class=\"list-group-item\">\n                  <div class=\"row\" style=\"padding-left:20px\">\n                    <small class=\"text-muted\">\n                      Event Type\n                    </small>\n                    <p><strong>"
    + alias2(lookupProperty(helpers,"lookup").call(alias1,depth0,(depths[1] != null ? lookupProperty(depths[1],"EVENT_TYPE") : depths[1]),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":88,"column":31},"end":{"line":88,"column":60}}}))
    + "</strong></p>\n                  </div>\n                </div>\n                <div class=\"list-group-item\">\n                  <div class=\"row\" style=\"padding-left:20px\">\n                    <small class=\"text-muted\">\n                      COSPAR ID\n                    </small>\n                    <p><strong>"
    + alias2(lookupProperty(helpers,"lookup").call(alias1,depth0,(depths[1] != null ? lookupProperty(depths[1],"OBJECT_COSPAR_ID") : depths[1]),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":96,"column":31},"end":{"line":96,"column":66}}}))
    + "</strong></p>\n                  </div>\n                </div>\n                <div class=\"list-group-item\">\n                  <div class=\"row\" style=\"padding-left:20px\">\n                    <small class=\"text-muted\">\n                      Catalogued Objects\n                    </small>\n                    <p><strong>"
    + alias2((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias3).call(alias1,"formatIntOrNA","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"CATALOGUED") : depths[1]),{"name":"chain","hash":{},"data":data,"loc":{"start":{"line":104,"column":31},"end":{"line":104,"column":84}}}))
    + "</strong></p>\n                  </div>\n                </div>\n                <div class=\"list-group-item\">\n                  <div class=\"row\" style=\"padding-left:20px\">\n                    <small class=\"text-muted\">\n                      Objects &gt; 1 cm\n                    </small>\n                    <p><strong>"
    + alias2((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias3).call(alias1,"formatIntOrNA","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"OBJECT_1CM") : depths[1]),{"name":"chain","hash":{},"data":data,"loc":{"start":{"line":112,"column":31},"end":{"line":112,"column":84}}}))
    + "</strong></p>\n                  </div>\n                </div>\n                <div class=\"list-group-item\">\n                  <div class=\"row\" style=\"padding-left:20px\">\n                    <small class=\"text-muted\">\n                      Objects &gt; 10 cm\n                    </small>\n                    <p><strong>"
    + alias2((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias3).call(alias1,"formatIntOrNA","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"OBJECT_10CM") : depths[1]),{"name":"chain","hash":{},"data":data,"loc":{"start":{"line":120,"column":31},"end":{"line":120,"column":85}}}))
    + "</strong></p>\n                  </div>\n                </div>\n                <div class=\"list-group-item\">\n                  <div class=\"row\" style=\"padding-left:20px\">\n                    <small class=\"text-muted\">\n                      Last Update\n                    </small>\n                    <p><strong>"
    + alias2((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias3).call(alias1,"formatDate","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"CREATION_DATE") : depths[1]),{"name":"chain","hash":{},"data":data,"loc":{"start":{"line":128,"column":31},"end":{"line":128,"column":84}}}))
    + "</strong></p>\n                  </div>\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"selectItem\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isDataAvailable") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":146,"column":9}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true,"useDepths":true});
