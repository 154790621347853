var $ = require('jquery')
var Backbone = require('backbone')
var _ = require('lodash')

let { SettingsModalView } = require('./SettingsModal')

var NavigationUserView = (exports.NavigationUserView = Backbone.View.extend({
  model: require('../models/WhoAmI'),

  template: require('../../templates/NavigationUserView.hbs'),

  initialize() {
    this.listenTo(this.model, 'all', this.render)
  },

  render() {
    var markup = this.template(this.model.toJSON())
    this.$el.html(markup)

    var settingsModalView = new SettingsModalView()
    var settingsViewMarkup = settingsModalView.render()
    $('body').append(settingsViewMarkup.el)

    console.log('rendered NavigationUserView')
    this.trigger('didRender')

    return this
  },
}))
