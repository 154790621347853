// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"chartText\" style=\"color: #000000;\">\n	"
    + alias4(((helper = (helper = lookupProperty(helpers,"xCol") || (depth0 != null ? lookupProperty(depth0,"xCol") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"xCol","hash":{},"data":data,"loc":{"start":{"line":2,"column":1},"end":{"line":2,"column":9}}}) : helper)))
    + ": "
    + alias4(((helper = (helper = lookupProperty(helpers,"xVal") || (depth0 != null ? lookupProperty(depth0,"xVal") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"xVal","hash":{},"data":data,"loc":{"start":{"line":2,"column":11},"end":{"line":2,"column":19}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"xUnit") || (depth0 != null ? lookupProperty(depth0,"xUnit") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"xUnit","hash":{},"data":data,"loc":{"start":{"line":2,"column":20},"end":{"line":2,"column":29}}}) : helper)))
    + "\n</div>\n<div class=\"row\" style=\"margin: 0px;\">\n	<div class=\"col-md-8\" style=\"padding: 0px;\">\n		<div class=\"chartText\" style=\"color: #4682B4;\">\n			Risk > 1 cm:\n		</div>\n	</div>\n	<div class=\"col-md-4\" style=\"padding: 0px;\">\n		<div class=\"chartText\" style=\"color: #4682B4;\">\n			"
    + alias4(((helper = (helper = lookupProperty(helpers,"risk1Cm") || (depth0 != null ? lookupProperty(depth0,"risk1Cm") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"risk1Cm","hash":{},"data":data,"loc":{"start":{"line":12,"column":3},"end":{"line":12,"column":14}}}) : helper)))
    + "%\n		</div>\n	</div>\n</div>\n<div class=\"row\" style=\"margin: 0px;\">\n	<div class=\"col-md-8\" style=\"padding: 0px;\">\n		<div class=\"chartText\" style=\"color: #EC916A;\">\n			Risk > 10 cm:\n		</div>\n	</div>\n	<div class=\"col-md-4\" style=\"padding: 0px;\">\n		<div class=\"chartText\" style=\"color: #EC916A;\">\n			"
    + alias4(((helper = (helper = lookupProperty(helpers,"risk10Cm") || (depth0 != null ? lookupProperty(depth0,"risk10Cm") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"risk10Cm","hash":{},"data":data,"loc":{"start":{"line":24,"column":3},"end":{"line":24,"column":15}}}) : helper)))
    + "%\n		</div>\n	</div>\n</div>\n";
},"useData":true});
