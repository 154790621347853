/*
	Model/collection to fetch/update user settings from admin page
*/
const Backbone = require('backbone')

const AdminSettingsModel = Backbone.Model.extend({
  urlRoot: '/api/protected/settings/adminSettings',
  idAttribute: 'userId',
})

const AdminSettingsCollection = Backbone.Collection.extend({
  model: AdminSettingsModel,
  url() {
    return '/api/protected/settings/adminSettings' + this.userId + this.eventId
  },

  initialize(options) {
    this.userId = '?userId=' + options.userId
    this.eventId = '&eventId=' + options.eventId
    this.fetch({ reset: true })
  },

  parse(response) {
    return response
  },
})

module.exports = AdminSettingsCollection
