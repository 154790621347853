var Backbone = require('backbone')
var _ = require('lodash')

var view = (module.exports = Backbone.View.extend({
  template: require('../../templates/CheckList.hbs'),

  initialize(options) {
    this.input = options.input
    this.input.checklist = options.collection
  },

  render() {
    this.$el.html(this.template(this.input))
    return this
  },

  loadFromFilter(inFilterSet) {
    var domElement, value, checkboxes, i

    domElement = this.el.querySelector('.field')
    checkboxes = this.el.querySelectorAll('.check')
    //console.error(checkboxes);
    for (i = 0; i < inFilterSet.length; ++i) {
      if (inFilterSet[i].hasOwnProperty(this.input.id)) {
        value = inFilterSet[i][this.input.id]
        if (value.$in) {
          value = value.$in.join(';')
        }

        domElement.value = value
        for (i = 0; i < checkboxes.length; ++i) {
          if (
            value.indexOf(checkboxes[i].nextSibling.nodeValue.trim()) !== -1
          ) {
            checkboxes[i].checked = true
          } else {
            checkboxes[i].checked = false
          }
        }

        return
      }
    }

    domElement.value = ''
    for (i = 0; i < checkboxes.length; ++i) {
      checkboxes[i].checked = false
    }
  },

  getFilter() {
    var inp = this.el.querySelector('.field')
    var value = inp.value.trim()
    var dbCol = inp.id.split('-')[1]
    var values = value.split(';')

    var ret = null
    if (value !== '') {
      ret = {}
      if (values.length > 1) {
        ret[dbCol] = { $in: values }
      } else {
        ret[dbCol] = value
      }
    }

    return ret
  },
}))
