// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal fade\" id=\"uploadFile\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"uploadFileLabel\" aria-hidden=\"true\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header\">\n				<button type=\"button\" class=\"close\" data-dismiss=\"modal\">\n					<span aria-hidden=\"true\">&times;</span>\n					<span class=\"sr-only\">Close</span>\n				</button>\n				<h4 class=\"modal-title\" id=\"uploadFileLabel\">\n					Upload File Item\n				</h4>\n			</div>\n			<div class=\"modal-body\">\n				<form class=\"form-horizontal\">\n					<div class=\"form-group\">\n						<label class=\"control-label col-sm-2\" for=\"uploadFileType\">File Type</label>\n						<div class=\"col-sm-10\">\n							<input type=\"text\" class=\"form-control\" name=\"fileTypeList\" list=\"fileTypes\" id=\"uploadFileType\" required>\n								<datalist id=\"fileTypes\">\n									<option value=\"Plot\"/>\n									<option value=\"Map\"/>\n									<option value=\"Text\"/>\n									<option value=\"Other\"/>\n								</datalist>\n							</input>\n						</div>\n					</div>\n					<div class=\"form-group\">\n						<label class=\"control-label col-sm-2\" for=\"uploadFileDate\">File Date</label>\n						<div class=\"col-sm-10\">\n							<input size=\"20\" type=\"text\" value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"currentDateTime") || (depth0 != null ? lookupProperty(depth0,"currentDateTime") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"currentDateTime","hash":{},"data":data,"loc":{"start":{"line":31,"column":43},"end":{"line":31,"column":62}}}) : helper)))
    + "\" class=\"uploadFileDate form-control\" id=\"uploadFileDate\">\n						</div>\n					</div>\n					<div class=\"form-group\">\n						<label class=\"control-label col-sm-2\" for=\"uploadFileName\">File </label>\n						<div class=\"col-sm-10\">\n							<input type=\"file\" id=\"uploadFileId\" class=\"form-control\" name=\"uploadFileName\" required/>\n						</div>\n					</div>\n				</form>\n			</div>\n			<div class=\"modal-footer\">\n				<button type=\"button\" class=\"btn btn-primary uploadFileSubmit\" data-dismiss=\"modal\">\n					<span class=\"glyphicon glyphicon-upload\" />\n					 Upload File\n				</button>\n				<button type=\"button\" class=\"btn btn-default\" data-dismiss=\"modal\">\n					<span class=\"glyphicon glyphicon-remove-circle\" />\n					Close\n				</button>\n			</div>\n		</div>\n	</div>\n</div>\n";
},"useData":true});
