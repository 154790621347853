/*
	Model/collection to fetch event objects from server.
*/
var Backbone = require('backbone')

var EventsDataModel = Backbone.Model.extend({
  urlRoot: '/api/protected/events',
  idAttribute: 'messageId',
})

var EventsDataCollection = Backbone.Collection.extend({
  model: EventsDataModel,
  url() {
    var baseUrl =
      this.view === 'detail'
        ? '/api/protected/events/detail'
        : '/api/protected/events'
    return baseUrl + '?size=' + this.size + '&query=' + this.query
  },

  initialize(options) {
    this.size = 1000000
    this.query = options.query
    this.view = options.view
    this.fetch({ reset: true })
  },

  parse(response) {
    return response.content
  },
})

module.exports = EventsDataCollection
