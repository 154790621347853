/*
	Model to store events filter attributes.
*/
var Backbone = require('backbone')

var EventsFilterModel = Backbone.Model.extend({})

var eventsFilterModel = new EventsFilterModel()

// make it a singleton
module.exports = eventsFilterModel
