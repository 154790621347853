// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<a class=\"btn btn-info btn-sm download\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"item") || (depth0 != null ? lookupProperty(depth0,"item") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"item","hash":{},"data":data,"loc":{"start":{"line":7,"column":51},"end":{"line":7,"column":59}}}) : helper)))
    + "\" download=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"itemName") || (depth0 != null ? lookupProperty(depth0,"itemName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"itemName","hash":{},"data":data,"loc":{"start":{"line":7,"column":71},"end":{"line":7,"column":83}}}) : helper)))
    + "\" id=\"download-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":7,"column":98},"end":{"line":7,"column":107}}}) : helper)))
    + "\">\n							<span class=\"glyphicon glyphicon-download-alt\"/>\n					</a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<button class=\"btn btn-info btn-sm fullScreen\" type=\"button\" id=\"fullscreen-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":12,"column":81},"end":{"line":12,"column":90}}}) : helper)))
    + "\">\n						<span class=\"glyphicon glyphicon-fullscreen\"/>\n					</button>\n					<button class=\"btn btn-danger btn-sm itemRemove\" type=\"button\" id=\"itemRemove-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":15,"column":83},"end":{"line":15,"column":92}}}) : helper)))
    + "\">\n						<span class=\"glyphicon glyphicon-remove\"/>\n					</button>\n";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		<div class=\"panel-footer\">\n			<center>\n				<div class=\"row\" style=\"color:#000000\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"dateFilter") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.program(19, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":31,"column":5},"end":{"line":117,"column":12}}})) != null ? stack1 : "")
    + "				</div>\n			</center>\n		</div>\n";
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<div class=\"col-md-1\">\n						</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isFirstItem") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.program(9, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":34,"column":6},"end":{"line":56,"column":13}}})) != null ? stack1 : "")
    + "						<div class=\"col-md-6\">\n							<center>\n								<div class=\"btn-group dropup\">\n									<button type=\"button\" class=\"btn btn-info dropdown-toggle "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"datesDropDownDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":67},"end":{"line":60,"column":113}}})) != null ? stack1 : "")
    + "\" data-toggle=\"dropdown\">\n										"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"displayDate") || (depth0 != null ? lookupProperty(depth0,"displayDate") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"displayDate","hash":{},"data":data,"loc":{"start":{"line":61,"column":10},"end":{"line":61,"column":25}}}) : helper)))
    + "\n										<span class=\"caret\"></span>\n									</button>\n									<ul class=\"dropdown-menu scrollable-menu\" role=\"menu\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"includeDates") : depth0),{"name":"each","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":10},"end":{"line":67,"column":19}}})) != null ? stack1 : "")
    + "									</ul>\n								</div>\n							</center>\n						</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isLastItem") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.program(17, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":72,"column":6},"end":{"line":94,"column":13}}})) != null ? stack1 : "")
    + "						<div class=\"col-md-1\">\n						</div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "							<div class=\"col-md-1\">\n								<div>\n									<span class=\"glyphicon glyphicon-fast-backward disable-color\"/>\n								</div>\n							</div>\n							<div class=\"col-md-1\">\n								<div>\n									<span class=\"glyphicon glyphicon-triangle-left disable-color\"/>\n								</div>\n							</div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "							<div class=\"col-md-1\">\n								<div class=\"clickable itemChange\" id=\"firstImg-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":47,"column":55},"end":{"line":47,"column":64}}}) : helper)))
    + "\">\n									<span class=\"glyphicon glyphicon-fast-backward\"/>\n								</div>\n							</div>\n							<div class=\"col-md-1\">\n								<div class=\"clickable itemChange\" id=\"prevImg-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":52,"column":54},"end":{"line":52,"column":63}}}) : helper)))
    + "\">\n									<span class=\"glyphicon glyphicon-triangle-left\"/>\n								</div>\n							</div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return " disabled ";
},"13":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "											<li class=\"clickable changeDate\" id=\""
    + alias2(alias1(depth0, depth0))
    + "|"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"index") : depths[1]), depth0))
    + "\">"
    + alias2(alias1(depth0, depth0))
    + "</li>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "							<div class=\"col-md-1\">\n								<div>\n									<span class=\"glyphicon glyphicon-triangle-right disable-color\"/>\n								</div>\n							</div>\n							<div class=\"col-md-1\">\n								<div>\n									<span class=\"glyphicon glyphicon-fast-forward disable-color\"/>\n								</div>\n							</div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "							<div class=\"col-md-1\">\n								<div class=\"clickable itemChange\" id=\"nextImg-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":85,"column":54},"end":{"line":85,"column":63}}}) : helper)))
    + "\">\n									<span class=\"glyphicon glyphicon-triangle-right\"/>\n								</div>\n							</div>\n							<div class=\"col-md-1\">\n								<div class=\"clickable itemChange\" id=\"lastImg-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":90,"column":54},"end":{"line":90,"column":63}}}) : helper)))
    + "\">\n									<span class=\"glyphicon glyphicon-fast-forward\"/>\n								</div>\n							</div>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"searchFilter") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":97,"column":5},"end":{"line":117,"column":5}}})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<div class=\"col-md-8 col-md-offset-2\">\n							<form class=\"form-inline "
    + alias4(((helper = (helper = lookupProperty(helpers,"searchClass") || (depth0 != null ? lookupProperty(depth0,"searchClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"searchClass","hash":{},"data":data,"loc":{"start":{"line":99,"column":32},"end":{"line":99,"column":47}}}) : helper)))
    + "\" id=\"textSearch-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":99,"column":64},"end":{"line":99,"column":73}}}) : helper)))
    + "\">\n								"
    + alias4(((helper = (helper = lookupProperty(helpers,"searchLabel") || (depth0 != null ? lookupProperty(depth0,"searchLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"searchLabel","hash":{},"data":data,"loc":{"start":{"line":100,"column":8},"end":{"line":100,"column":23}}}) : helper)))
    + "\n								<div class=\"form-group\">\n									<div class=\"input-group\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"searchNumber") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.program(23, data, 0),"data":data,"loc":{"start":{"line":103,"column":10},"end":{"line":107,"column":17}}})) != null ? stack1 : "")
    + "										<div class=\"input-group-btn\">\n											<button class=\"btn btn-default btn-"
    + alias4(((helper = (helper = lookupProperty(helpers,"searchClass") || (depth0 != null ? lookupProperty(depth0,"searchClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"searchClass","hash":{},"data":data,"loc":{"start":{"line":109,"column":46},"end":{"line":109,"column":61}}}) : helper)))
    + "\" type=\"submit\">\n												<span class=\"fa fa-check\"/>\n											</button>\n										</div>\n									</div>\n								</div>\n							</form>\n						</div>\n					";
},"21":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "											<input type=\"number\" class=\"form-control searchText\" min=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"searchMin") || (depth0 != null ? lookupProperty(depth0,"searchMin") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"searchMin","hash":{},"data":data,"loc":{"start":{"line":104,"column":69},"end":{"line":104,"column":82}}}) : helper)))
    + "\" max=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"searchMax") || (depth0 != null ? lookupProperty(depth0,"searchMax") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"searchMax","hash":{},"data":data,"loc":{"start":{"line":104,"column":89},"end":{"line":104,"column":102}}}) : helper)))
    + "\" step=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"searchStep") || (depth0 != null ? lookupProperty(depth0,"searchStep") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"searchStep","hash":{},"data":data,"loc":{"start":{"line":104,"column":110},"end":{"line":104,"column":124}}}) : helper)))
    + "\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"searchValue") || (depth0 != null ? lookupProperty(depth0,"searchValue") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"searchValue","hash":{},"data":data,"loc":{"start":{"line":104,"column":133},"end":{"line":104,"column":148}}}) : helper)))
    + "\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"searchClass") || (depth0 != null ? lookupProperty(depth0,"searchClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"searchClass","hash":{},"data":data,"loc":{"start":{"line":104,"column":154},"end":{"line":104,"column":169}}}) : helper)))
    + "-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":104,"column":170},"end":{"line":104,"column":179}}}) : helper)))
    + "\">\n";
},"23":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "											<input type=\"text\" class=\"form-control searchText\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"searchClass") || (depth0 != null ? lookupProperty(depth0,"searchClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"searchClass","hash":{},"data":data,"loc":{"start":{"line":106,"column":66},"end":{"line":106,"column":81}}}) : helper)))
    + "-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":106,"column":82},"end":{"line":106,"column":91}}}) : helper)))
    + "\">\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"panel panel-primary\">\n	<div class=\"panel-heading\">\n		<div class=\"panel-title\" style=\"display:flex;justify-content:space-between\">\n			"
    + alias4(((helper = (helper = lookupProperty(helpers,"panelHeading") || (depth0 != null ? lookupProperty(depth0,"panelHeading") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"panelHeading","hash":{},"data":data,"loc":{"start":{"line":4,"column":3},"end":{"line":4,"column":19}}}) : helper)))
    + "\n			<div class=\"btn-group\" role=\"group\" style=\"display:inherit\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"downloadIconDisplay") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":10,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isSingleView") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":18,"column":15}}})) != null ? stack1 : "")
    + "			</div>\n		</div>\n	</div>\n	<div class=\"panel-body\" style=\"height:"
    + alias4(((helper = (helper = lookupProperty(helpers,"panelBodyHeight") || (depth0 != null ? lookupProperty(depth0,"panelBodyHeight") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"panelBodyHeight","hash":{},"data":data,"loc":{"start":{"line":22,"column":39},"end":{"line":22,"column":58}}}) : helper)))
    + "\">\n		<center>\n			<div class=\"detailItem\" id=\"detailItem-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":24,"column":42},"end":{"line":24,"column":51}}}) : helper)))
    + "\"></div>\n		</center>\n	</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"panelFooter") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":1},"end":{"line":121,"column":8}}})) != null ? stack1 : "")
    + "\n</div>\n";
},"useData":true,"useDepths":true});
