// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<table class=\"table table-striped table-hover head-fixed\">\n				<thead>\n					<tr class=\"col-size-row\" style=\"border-bottom-style: solid\">\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"eventsColumns") : depth0),{"name":"with","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":6},"end":{"line":55,"column":15}}})) != null ? stack1 : "")
    + "					</tr>\n				</thead>\n				<tbody style=\"height:"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"divHeight") || (depth0 != null ? lookupProperty(depth0,"divHeight") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"divHeight","hash":{},"data":data,"loc":{"start":{"line":58,"column":25},"end":{"line":58,"column":38}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"eventsColumns") : depth0),{"name":"with","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":5},"end":{"line":83,"column":14}}})) != null ? stack1 : "")
    + "				</tbody>\n			</table>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "							<th class=\"col-size-events-icon\"></th>\n							<th class=\"col-size-events\">\n								<div class=\"clickable sort addSortButton-"
    + alias4(((helper = (helper = lookupProperty(helpers,"NAME") || (depth0 != null ? lookupProperty(depth0,"NAME") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"NAME","hash":{},"data":data,"loc":{"start":{"line":10,"column":49},"end":{"line":10,"column":57}}}) : helper)))
    + "\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"NAME") || (depth0 != null ? lookupProperty(depth0,"NAME") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"NAME","hash":{},"data":data,"loc":{"start":{"line":10,"column":63},"end":{"line":10,"column":71}}}) : helper)))
    + "\">\n									<span title=\"Object1 Name/Object2 Name\" class=\"word-hyphenate\">Name</span>\n								</div>\n							</th>\n							<th class=\"col-size-events\">\n								<div class=\"clickable sort addSortButton-"
    + alias4(((helper = (helper = lookupProperty(helpers,"EVENT_DATE") || (depth0 != null ? lookupProperty(depth0,"EVENT_DATE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"EVENT_DATE","hash":{},"data":data,"loc":{"start":{"line":15,"column":49},"end":{"line":15,"column":63}}}) : helper)))
    + "\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"EVENT_DATE") || (depth0 != null ? lookupProperty(depth0,"EVENT_DATE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"EVENT_DATE","hash":{},"data":data,"loc":{"start":{"line":15,"column":69},"end":{"line":15,"column":83}}}) : helper)))
    + "\">\n									<span class=\"word-hyphenate\">Event Date</span>\n								</div>\n							</th>\n							<th class=\"col-size-events\">\n								<div class=\"clickable sort addSortButton-"
    + alias4(((helper = (helper = lookupProperty(helpers,"EVENT_TYPE") || (depth0 != null ? lookupProperty(depth0,"EVENT_TYPE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"EVENT_TYPE","hash":{},"data":data,"loc":{"start":{"line":20,"column":49},"end":{"line":20,"column":63}}}) : helper)))
    + "\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"EVENT_TYPE") || (depth0 != null ? lookupProperty(depth0,"EVENT_TYPE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"EVENT_TYPE","hash":{},"data":data,"loc":{"start":{"line":20,"column":69},"end":{"line":20,"column":83}}}) : helper)))
    + "\">\n									<span class=\"word-hyphenate\">Event Type</span>\n								</div>\n							</th>\n							<th class=\"col-size-events\">\n								<div class=\"clickable sort addSortButton-"
    + alias4(((helper = (helper = lookupProperty(helpers,"OBJECT_COSPAR_ID") || (depth0 != null ? lookupProperty(depth0,"OBJECT_COSPAR_ID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"OBJECT_COSPAR_ID","hash":{},"data":data,"loc":{"start":{"line":25,"column":49},"end":{"line":25,"column":69}}}) : helper)))
    + "\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"OBJECT_COSPAR_ID") || (depth0 != null ? lookupProperty(depth0,"OBJECT_COSPAR_ID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"OBJECT_COSPAR_ID","hash":{},"data":data,"loc":{"start":{"line":25,"column":75},"end":{"line":25,"column":95}}}) : helper)))
    + "\">\n									<span title=\"Object1 COSPAR ID/Object2 COSPAR ID\" class=\"word-hyphenate\">COSPAR ID</span>\n								</div>\n							</th>\n							<th class=\"col-size-events\">\n								<div class=\"clickable sort addSortButton-"
    + alias4(((helper = (helper = lookupProperty(helpers,"CATALOGUED") || (depth0 != null ? lookupProperty(depth0,"CATALOGUED") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CATALOGUED","hash":{},"data":data,"loc":{"start":{"line":30,"column":49},"end":{"line":30,"column":63}}}) : helper)))
    + "\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"CATALOGUED") || (depth0 != null ? lookupProperty(depth0,"CATALOGUED") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CATALOGUED","hash":{},"data":data,"loc":{"start":{"line":30,"column":69},"end":{"line":30,"column":83}}}) : helper)))
    + "\">\n									<span class=\"word-hyphenate\">Catalogued Objects</span>\n								</div>\n							</th>\n							<th class=\"col-size-events\">\n								<div class=\"clickable sort addSortButton-"
    + alias4(((helper = (helper = lookupProperty(helpers,"CATALOGUED_ON_ORBIT") || (depth0 != null ? lookupProperty(depth0,"CATALOGUED_ON_ORBIT") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CATALOGUED_ON_ORBIT","hash":{},"data":data,"loc":{"start":{"line":35,"column":49},"end":{"line":35,"column":72}}}) : helper)))
    + "\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"CATALOGUED_ON_ORBIT") || (depth0 != null ? lookupProperty(depth0,"CATALOGUED_ON_ORBIT") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CATALOGUED_ON_ORBIT","hash":{},"data":data,"loc":{"start":{"line":35,"column":78},"end":{"line":35,"column":101}}}) : helper)))
    + "\">\n									<span class=\"word-hyphenate\">Objects on Orbit</span>\n								</div>\n							</th>\n							<th class=\"col-size-events\">\n								<div class=\"clickable sort addSortButton-"
    + alias4(((helper = (helper = lookupProperty(helpers,"OBJECT_1CM") || (depth0 != null ? lookupProperty(depth0,"OBJECT_1CM") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"OBJECT_1CM","hash":{},"data":data,"loc":{"start":{"line":40,"column":49},"end":{"line":40,"column":63}}}) : helper)))
    + "\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"OBJECT_1CM") || (depth0 != null ? lookupProperty(depth0,"OBJECT_1CM") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"OBJECT_1CM","hash":{},"data":data,"loc":{"start":{"line":40,"column":69},"end":{"line":40,"column":83}}}) : helper)))
    + "\">\n									<span class=\"word-hyphenate\">Objects &gt; 1 cm</span>\n								</div>\n							</th>\n							<th class=\"col-size-events\">\n								<div class=\"clickable sort addSortButton-"
    + alias4(((helper = (helper = lookupProperty(helpers,"OBJECT_10CM") || (depth0 != null ? lookupProperty(depth0,"OBJECT_10CM") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"OBJECT_10CM","hash":{},"data":data,"loc":{"start":{"line":45,"column":49},"end":{"line":45,"column":64}}}) : helper)))
    + "\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"OBJECT_10CM") || (depth0 != null ? lookupProperty(depth0,"OBJECT_10CM") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"OBJECT_10CM","hash":{},"data":data,"loc":{"start":{"line":45,"column":70},"end":{"line":45,"column":85}}}) : helper)))
    + "\">\n									<span class=\"word-hyphenate\">Objects &gt; 10 cm</span>\n								</div>\n							</th>\n							<th class=\"col-size-events\">\n								<div class=\"clickable sort addSortButton-"
    + alias4(((helper = (helper = lookupProperty(helpers,"CREATION_DATE") || (depth0 != null ? lookupProperty(depth0,"CREATION_DATE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CREATION_DATE","hash":{},"data":data,"loc":{"start":{"line":50,"column":49},"end":{"line":50,"column":66}}}) : helper)))
    + "\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"CREATION_DATE") || (depth0 != null ? lookupProperty(depth0,"CREATION_DATE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CREATION_DATE","hash":{},"data":data,"loc":{"start":{"line":50,"column":72},"end":{"line":50,"column":89}}}) : helper)))
    + "\">\n									<span class=\"word-hyphenate\">Last Update</span>\n								</div>\n							</th>\n							<th class=\"col-size-events-icon\"><span title=\"\"></span></th>\n";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"collection") : depths[1]),{"name":"each","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":6},"end":{"line":82,"column":15}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "							<tr class=\"col-size-row\">\n								<td class=\"col-size-events-icon\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isFollowed") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":9},"end":{"line":65,"column":16}}})) != null ? stack1 : "")
    + "								</td>\n								<td class=\"col-size-events word-hyphenate\">"
    + alias2(lookupProperty(helpers,"lookup").call(alias1,depth0,(depths[1] != null ? lookupProperty(depths[1],"NAME") : depths[1]),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":67,"column":51},"end":{"line":67,"column":74}}}))
    + "</td>\n								<td class=\"col-size-events word-hyphenate\">"
    + alias2((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias3).call(alias1,"formatDate","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"EVENT_DATE") : depths[1]),{"name":"chain","hash":{},"data":data,"loc":{"start":{"line":68,"column":51},"end":{"line":68,"column":101}}}))
    + "</td>\n								<td class=\"col-size-events word-hyphenate\">"
    + alias2(lookupProperty(helpers,"lookup").call(alias1,depth0,(depths[1] != null ? lookupProperty(depths[1],"EVENT_TYPE") : depths[1]),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":69,"column":51},"end":{"line":69,"column":80}}}))
    + "</td>\n								<td class=\"col-size-events word-hyphenate\">"
    + alias2(lookupProperty(helpers,"lookup").call(alias1,depth0,(depths[1] != null ? lookupProperty(depths[1],"OBJECT_COSPAR_ID") : depths[1]),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":70,"column":51},"end":{"line":70,"column":86}}}))
    + "</td>\n								<td class=\"col-size-events word-hyphenate\">"
    + alias2((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias3).call(alias1,"formatIntOrNA","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"CATALOGUED") : depths[1]),{"name":"chain","hash":{},"data":data,"loc":{"start":{"line":71,"column":51},"end":{"line":71,"column":104}}}))
    + "</td>\n								<td class=\"col-size-events word-hyphenate\">"
    + alias2((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias3).call(alias1,"formatIntOrNA","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"CATALOGUED_ON_ORBIT") : depths[1]),{"name":"chain","hash":{},"data":data,"loc":{"start":{"line":72,"column":51},"end":{"line":72,"column":113}}}))
    + "</td>\n								<td class=\"col-size-events word-hyphenate\">"
    + alias2((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias3).call(alias1,"formatIntOrNA","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"OBJECT_1CM") : depths[1]),{"name":"chain","hash":{},"data":data,"loc":{"start":{"line":73,"column":51},"end":{"line":73,"column":104}}}))
    + "</td>\n								<td class=\"col-size-events word-hyphenate\">"
    + alias2((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias3).call(alias1,"formatIntOrNA","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"OBJECT_10CM") : depths[1]),{"name":"chain","hash":{},"data":data,"loc":{"start":{"line":74,"column":51},"end":{"line":74,"column":105}}}))
    + "</td>\n								<td class=\"col-size-events word-hyphenate\">"
    + alias2((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias3).call(alias1,"formatDate","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"CREATION_DATE") : depths[1]),{"name":"chain","hash":{},"data":data,"loc":{"start":{"line":75,"column":51},"end":{"line":75,"column":104}}}))
    + "</td>\n								<td class=\"col-size-events-icon word-hyphenate\">\n									<button class=\"btn btn-default btn-xs viewDetails\" id=\""
    + alias2(lookupProperty(helpers,"lookup").call(alias1,depth0,(depths[1] != null ? lookupProperty(depths[1],"EVENT_ID") : depths[1]),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":77,"column":64},"end":{"line":77,"column":91}}}))
    + "\" type=\"button\" title=\"Click to view details of this object\">\n										<span class=\"glyphicon glyphicon-new-window\"></span>\n									</button>\n								</td>\n							</tr>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "										<span class=\"fa fa-star\" title=\"Event Followed\" />\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "			<center><span class=\"fa fa-spinner fa-pulse fa-3x fa-fw margin-bottom\" style=\"margin:100px auto auto\"></span></center>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"eventsCollection\">\n	<div class=\"row\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"dataPopulated") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(8, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":88,"column":9}}})) != null ? stack1 : "")
    + "	</div>\n</div>\n";
},"useData":true,"useDepths":true});
