/*
	Display messages and send it
*/
var Backbone = require('backbone')
var _ = require('lodash')
var $ = require('jquery')
var moment = require('moment')

var messageBus = require('../models/MessageBus')
var whoAmI = require('../models/WhoAmI')

var AskAdminMessagesCollection = require('../models/AskAdminMessagesCollection')

var AskAdminMessagesView = (exports.AskAdminMessagesView = Backbone.View.extend(
  {
    template: require('../../templates/AskAdminMessages.hbs'),

    initialize(options) {
      console.log('Initialized Ask Admin Messages View')

      this.view = options.view
      this.eventId = options.eventId
      this.userId = options.userId
      this.isAdminUser = options.isAdminUser
    },

    events: {
      'click button.sendMessage': 'sendMessage',
      'click button.eraseMessage': 'eraseMessage',
    },

    render(collection, isBackButtonDisplay) {
      var markup
      this.collection = collection
      markup = this.template({
        collection: collection.toJSON(),
        divHeightPanelBody: isBackButtonDisplay ? '190px' : '210px',
        backButtonDisplay: isBackButtonDisplay,
        view: this.view,
        isAdminUser: this.isAdminUser,
      })
      this.$el.html(markup)

      return this
    },

    sendMessage(e) {
      e.preventDefault()
      console.log(e)
      var message = this.el.querySelector('#askAdminMessage-' + this.view).value
      if (message !== '') {
        var collection = this.collection
        this.askAdminMessagesModel = new collection.model()

        this.askAdminMessagesModel.set({
          message,
          eventId: this.eventId,
          messageTimeStamp: moment.utc().format('DD MMM YYYY HH:mm:ss'),
          userId: this.userId,
          isMessagePost: true,
        })

        this.askAdminMessagesModel.idAttribute = null

        this.askAdminMessagesModel
          .save()
          .success(function (model) {
            collection.add(model)
          })
          .error(function (model, error) {
            messageBus.trigger('growl', {
              type: 'danger',
              content: 'Message not sent' + error.responseText,
            })
          })
      }
    },

    eraseMessage(e) {
      this.el.querySelector('#askAdminMessage-' + this.view).value = ''
    },
  },
))
