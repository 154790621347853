/*
	Plot events date history view plot
*/

var Backbone = require('backbone')
var _ = require('lodash')
var $ = require('jquery')
var moment = require('moment')
var d3 = require('d3')

var eventsColumns = require('../../../common/config/constants').eventsFieldNames

var DetailPlotView = (exports.DetailPlotView = Backbone.View.extend({
  template: require('../../templates/DetailPlotView.hbs'),
  toolTipTemplate: require('../../templates/DetailRiskPlotToolTip.hbs'),

  divDisplayedItems: {},

  isSingleView: true,

  elementAtIndexZero: null,

  initialize(options) {
    console.log('Initialized Details Plot View')
    this.height = options.height
    this.width = options.width
  },

  renderRiskVsAltitude(
    collection,
    maxRisk,
    maxAltitude,
    minAltitude,
    objectName,
    objectCosparId,
  ) {
    var markup = this.template({})
    this.$el.html(markup)

    var data = []

    var risk1cm = (function () {
      var dataTmp = []
      for (var i = 0; i < collection.length; ++i) {
        dataTmp.push({
          x: +collection[i].altitude,
          risk: +collection[i].risk1cm * 100,
          type: 'Objects > 1 cm',
        })
      }
      return dataTmp
    })()

    var risk10cm = (function () {
      var dataTmp = []
      for (var i = 0; i < collection.length; ++i) {
        dataTmp.push({
          x: +collection[i].altitude,
          risk: +collection[i].risk10cm * 100,
          type: 'Objects > 10 cm',
        })
      }
      return dataTmp
    })()

    data.push({
      type: 'Objects > 1 cm',
      values: risk1cm,
    })

    data.push({
      type: 'Objects > 10 cm',
      values: risk10cm,
    })

    var margin = { top: 20, right: 20, bottom: 50, left: 70 },
      width = this.width - margin.left - margin.right,
      height = this.height - margin.top - margin.bottom

    var x = d3.scale.linear().range([0, width])

    var y = d3.scale.linear().range([height, 0])

    var xAxis = d3.svg.axis().scale(x).orient('bottom')
    //.ticks((this.dataset[0].values.length < width/100) ? this.dataset[0].values.length : (width/100))

    var svg = d3
      .select(this.el.querySelector('.chart-area'))
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')

    x.domain([minAltitude, maxAltitude])

    y.domain([0, maxRisk + 1])

    svg
      .append('g')
      .attr('class', 'x axis')
      .attr('transform', 'translate(0,' + height + ')')
      .call(xAxis)
      .append('text')
      .attr('y', margin.bottom - 20)
      .attr('x', width / 2)
      .attr('dy', '.71em')
      .style('text-anchor', 'middle')
      .text('Altitude (km)')

    return this.renderRiskPlot(
      svg,
      eventsColumns.ALTITUDE,
      x,
      y,
      collection,
      margin,
      width,
      height,
      data,
      objectName,
      objectCosparId,
    )
  },

  renderRiskVsTime(collection, maxRisk, objectName, objectCosparId) {
    var markup = this.template({})
    this.$el.html(markup)

    var data = []

    var risk1cm = (function () {
      var dataTmp = []
      for (var i = 0; i < collection.length; ++i) {
        dataTmp.push({
          x: moment.utc(collection[i].epoch).toDate(),
          risk: +collection[i].risk1cm * 100,
          type: 'Objects > 1 cm',
        })
      }
      return dataTmp
    })()

    var risk10cm = (function () {
      var dataTmp = []
      for (var i = 0; i < collection.length; ++i) {
        dataTmp.push({
          x: moment.utc(collection[i].epoch).toDate(),
          risk: +collection[i].risk10cm * 100,
          type: 'Objects > 10 cm',
        })
      }
      return dataTmp
    })()

    data.push({
      type: 'Objects > 1 cm',
      values: risk1cm,
    })

    data.push({
      type: 'Objects > 10 cm',
      values: risk10cm,
    })

    var margin = { top: 20, right: 20, bottom: 50, left: 70 },
      width = this.width - margin.left - margin.right,
      height = this.height - margin.top - margin.bottom

    var x = d3.time.scale.utc().range([0, width])

    var y = d3.scale.linear().range([height, 0])

    var xAxis = d3.svg.axis().scale(x).orient('bottom')
    //.ticks((this.dataset[0].values.length < width/100) ? this.dataset[0].values.length : (width/100))

    var svg = d3
      .select(this.el.querySelector('.chart-area'))
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')

    x.domain([
      moment
        .utc(
          d3
            .min(data, function (c) {
              return d3.min(c.values, function (v) {
                return v.x
              })
            })
            .getTime(),
        )
        .toDate(),
      moment
        .utc(
          d3
            .max(data, function (c) {
              return d3.max(c.values, function (v) {
                return v.x
              })
            })
            .getTime(),
        )
        .toDate(),
    ])

    y.domain([0, maxRisk + 1])

    svg
      .append('g')
      .attr('class', 'x axis')
      .attr('transform', 'translate(0,' + height + ')')
      .call(xAxis)
      .append('text')
      .attr('y', margin.bottom - 20)
      .attr('x', width / 2)
      .attr('dy', '.71em')
      .style('text-anchor', 'middle')
      .text('Time')

    return this.renderRiskPlot(
      svg,
      eventsColumns.EPOCH,
      x,
      y,
      collection,
      margin,
      width,
      height,
      data,
      objectName,
      objectCosparId,
    )
  },

  renderRiskPlot(
    svg,
    xCol,
    x,
    y,
    collection,
    margin,
    width,
    height,
    data,
    name,
    cosparId,
  ) {
    var popup_left = 65
    var toolTipTemplate = this.toolTipTemplate

    var yAxis = d3.svg.axis().scale(y).orient('left')
    //.ticks((this.dataset[0].values.length < height/30) ? this.dataset[0].values.length : (height/30));

    var line = d3.svg
      .line()
      .x(function (d) {
        return x(d.x)
      })
      .y(function (d) {
        return y(d.risk)
      })
      .interpolate('linear')

    var colors = {
      'Objects > 1 cm': '#4682B4',
      'Objects > 10 cm': '#EC916A',
    }

    svg
      .append('g')
      .attr('class', 'y axis')
      .call(yAxis)
      .append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', 0 - margin.left)
      .attr('x', 0 - height / 2)
      .attr('dy', '.71em')
      .style('text-anchor', 'middle')
      .text('Risk Increase (%)')

    var plotArea = svg.append('g').attr('class', 'plotArea')

    var riskLine = plotArea.append('g').attr('class', 'riskLine')

    var legendArea = plotArea.append('g').attr('class', 'legendArea')

    var legend = legendArea
      .selectAll('g')
      .data(data)
      .enter()
      .append('g')
      .attr('class', 'legend')

    legend
      .append('rect')
      .attr('x', width - 100)
      .attr('y', function (d, i) {
        return i * 20
      })
      .attr('width', 10)
      .attr('height', 10)
      .style('fill', function (d) {
        return colors[d.type]
      })

    legend
      .append('text')
      .attr('class', 'chartText')
      .attr('x', width - 85)
      .attr('y', function (d, i) {
        return i * 20 + 9
      })
      .text(function (d) {
        return d.type
      })

    //var title = titleArea.selectAll('g')
    //	.attr('class', 'title');

    svg
      .append('text')
      .attr('class', 'chartText')
      .attr('x', width / 2)
      .attr('y', 0 - margin.top / 2)
      .attr('text-anchor', 'middle')
      .text(name)

    svg
      .append('text')
      .attr('class', 'chartText')
      .attr('x', width / 2)
      .attr('y', 12 - margin.top / 2)
      .attr('text-anchor', 'middle')
      .text(cosparId)

    var dataLine = riskLine
      .selectAll('.dataLine')
      .data(data)
      .enter()
      .append('g')
      .attr('class', 'dataLine')

    dataLine
      .append('path')
      .attr('class', 'line')
      .attr('d', function (d) {
        return line(d.values)
      })
      .style('stroke', function (d) {
        return colors[d.type]
      })
      .style('stroke-width', '3px')

    // Hover line.
    var hoverLineGroup = plotArea.append('g').attr('class', 'hover-line')

    var hoverLine = hoverLineGroup
      .append('line')
      .attr('class', 'hoverLine')
      .attr('x1', 10)
      .attr('x2', 10)
      .attr('y1', 25)
      .attr('y2', height)

    // Hide hover line by default.
    hoverLineGroup.style('opacity', 1e-6)

    //Popup on mouse hover
    var popup = d3
      .select(this.el.querySelector('.chart-area'))
      .append('div')
      .attr('class', 'popup')
      .style('opacity', 0)
      .style('left', '200px')
      .style('top', '80px')
      .style('display', 'block')
    popup.append('text').attr('class', 'popup data').attr('x', 20).attr('y', 27)

    // Create invisible rect for mouse tracking
    plotArea
      .append('rect')
      .attr('width', width)
      .attr('height', height)
      .attr('x', 0)
      .attr('y', 0)
      .attr('id', 'mouseOverChart')
      .style('fill', 'white')
      .on('mousemove', mousemove)
      .on('mouseout', mouseout)

    function findRiskatX(values, xValue) {
      var returnValue = {}

      xValue = +xValue

      if (_.uniq(_.map(values, xCol)).indexOf(xValue) > -1) {
        // if xCol already found in collection, return it.
        returnValue = _.find(values, function (o) {
          if (o[xCol] === xValue) {
            return true
          }
        })
      } else {
        // if xCol not found
        var allX = _.uniq(_.map(values, xCol))

        // get the xCol in collection which is just smaller than the specified xCol
        // and the xCol that is just larger than the specified xCol
        // to calculate points between the smaller and the larger xCol
        var smallX = allX[0]
        var largeX = allX[allX.length - 1]
        for (var i = 1; i < allX.length; i++) {
          if (allX[i] < xValue) {
            smallX = allX[i]
          } else {
            largeX = allX[i]
            break
          }
        }
        var largeSmallDiff = largeX - smallX

        // difference between specified altitude and the small xCol
        var xSmallDiff = xValue - smallX

        // get object from the collection equal to small xCol
        var smallObject = _.find(values, function (o) {
          if (o[xCol] === smallX) {
            return true
          }
        })

        // get object from the collection equal to large xCol
        var largeObject = _.find(values, function (o) {
          if (o[xCol] === largeX) {
            return true
          }
        })

        // calculate risks for the specified xCol and push it to return collection
        var largeSmallRisk1CmDiff = largeObject.risk1cm - smallObject.risk1cm
        returnValue.risk1cm =
          smallObject.risk1cm +
          (largeSmallRisk1CmDiff / largeSmallDiff) * xSmallDiff
        var largeSmallRisk10CmDiff = largeObject.risk10cm - smallObject.risk10cm
        returnValue.risk10cm =
          smallObject.risk10cm +
          (largeSmallRisk10CmDiff / largeSmallDiff) * xSmallDiff
      }

      return returnValue
    }

    // Add mouseover events.
    function mousemove() {
      var mouse_x = d3.mouse(this)[0]
      var mouse_y = d3.mouse(this)[1]
      var graph_y = y.invert(mouse_y)
      var graph_x = x.invert(mouse_x)
      var riskObj = findRiskatX(collection, graph_x)

      var left = mouse_x
      if (left > width - 128) {
        left = width - 128
      } else {
        if (left < 128) {
          left = 128
        }
      }

      popup
        .style('opacity', 1)
        .style('left', left + 'px')
        .style('top', '80px')

      var tipHtml = toolTipTemplate({
        xCol: xCol === eventsColumns.EPOCH ? 'Time' : 'Altitude',
        xUnit: xCol === eventsColumns.EPOCH ? '' : ' km',
        xVal:
          xCol === eventsColumns.EPOCH
            ? moment.utc(graph_x).format('MMM YYYY')
            : Math.round(graph_x * 100) / 100,
        risk1Cm: Math.round(riskObj.risk1cm * 10000) / 100,
        risk10Cm: Math.round(riskObj.risk10cm * 10000) / 100,
      })
      popup.html(tipHtml)

      hoverLine.attr('x1', mouse_x).attr('x2', mouse_x)
      hoverLineGroup.style('opacity', 1)
    }
    function mouseout() {
      hoverLineGroup.style('opacity', 1e-6)
      popup.style('opacity', 0)
    }

    return this
  },
}))
