var Backbone = require('backbone')
var _ = require('lodash')

var view = (module.exports = Backbone.View.extend({
  template: require('../../templates/TextInput.hbs'),

  initialize(options) {
    this.input = options.input
    this.re = /[%_]/
  },

  render() {
    this.$el.html(this.template(this.input))
    return this
  },

  loadFromFilter(inFilterSet) {
    var filter, domElement, i

    filter = null
    domElement = this.el.querySelector('.field')
    for (i = 0; i < inFilterSet.length; ++i) {
      if (inFilterSet[i].hasOwnProperty(this.input.id)) {
        filter = inFilterSet[i]
        domElement.value = filter[this.input.id]
        return
      }
    }

    domElement.value = ''
  },

  getFilter() {
    var inp = this.el.querySelector('.field')
    var value = inp.value.trim()
    var dbCol = inp.id.split('-')[1]

    var ret = null
    if (value !== '') {
      ret = {}
      if (value.charAt(0) === '!') {
        ret[dbCol] = { $ne: value.substring(1) }
      } else if (this.re.test(value)) {
        // if value is regexp
        ret[dbCol] = { $regex: value }
      } else {
        ret[dbCol] = value
      }
    }
    return ret
  },
}))
