'use strict'

console.log('main.js loaded')

/*
//Testing secure manual request with automatic cookies:

var xmlhttp = new XMLHttpRequest();
xmlhttp.open("POST","login",true);
xmlhttp.setRequestHeader(
	"Content-Type"
	,"application/x-www-form-urlencoded"
);
xmlhttp.send("username=super&password=super");
xmlhttp.onload = function()
{
	console.log(JSON.stringify(window.location,null,'\t'));
	console.warn(arguments);

	var xmlhttp = new XMLHttpRequest();
	xmlhttp.open(
		"GET"
		,"/somethingelse"		//,"http://" + window.location.hostname + ":8081/" + "somethingelse"
		,true
	);
	xmlhttp.onload = function()
	{
		console.warn(arguments);
	}
	xmlhttp.send();
}
*/

/*
TODO: csrf stuff
var token = $('meta[name="csrf-token"]').attr('content');
if (token) xhr.setRequestHeader('X-CSRF-Token', token);
*/

require('./app.js')
