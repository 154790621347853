// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"eventDetails") : depths[1]),{"name":"with","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":6},"end":{"line":9,"column":15}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  					<span title=\""
    + alias2(lookupProperty(helpers,"lookup").call(alias1,depth0,(depths[1] != null ? lookupProperty(depths[1],"NAME") : depths[1]),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":8,"column":43}}}))
    + "\">"
    + alias2(lookupProperty(helpers,"lookup").call(alias1,depth0,(depths[1] != null ? lookupProperty(depths[1],"NAME") : depths[1]),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":8,"column":45},"end":{"line":8,"column":68}}}))
    + "</span>\n";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"eventDetails") : depths[1]),{"name":"with","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":4},"end":{"line":41,"column":13}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<b>Fragmentation Id</b> : "
    + alias2(lookupProperty(helpers,"lookup").call(alias1,depth0,(depths[1] != null ? lookupProperty(depths[1],"EVENT_ID") : depths[1]),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":21,"column":31},"end":{"line":21,"column":58}}}))
    + "<br>\n					<b>Name</b> : "
    + alias2(lookupProperty(helpers,"lookup").call(alias1,depth0,(depths[1] != null ? lookupProperty(depths[1],"NAME") : depths[1]),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":22,"column":19},"end":{"line":22,"column":42}}}))
    + "<br>\n					<b>Event Date</b> : "
    + alias2((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias3).call(alias1,"formatDate","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"EVENT_DATE") : depths[1]),{"name":"chain","hash":{},"data":data,"loc":{"start":{"line":23,"column":25},"end":{"line":23,"column":75}}}))
    + "<br>\n					<b>Event Type</b> : "
    + alias2(lookupProperty(helpers,"lookup").call(alias1,depth0,(depths[1] != null ? lookupProperty(depths[1],"EVENT_TYPE") : depths[1]),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":24,"column":25},"end":{"line":24,"column":54}}}))
    + "<br>\n					<b>COSPAR ID</b> : "
    + alias2(lookupProperty(helpers,"lookup").call(alias1,depth0,(depths[1] != null ? lookupProperty(depths[1],"OBJECT_COSPAR_ID") : depths[1]),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":25,"column":24},"end":{"line":25,"column":59}}}))
    + "<br>\n					<b>Catalogued Objects</b> : "
    + alias2((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias3).call(alias1,"formatIntOrNA","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"CATALOGUED") : depths[1]),{"name":"chain","hash":{},"data":data,"loc":{"start":{"line":26,"column":33},"end":{"line":26,"column":86}}}))
    + "<br>\n					<b>Objects on Orbit</b> : "
    + alias2((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias3).call(alias1,"formatIntOrNA","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"CATALOGUED_ON_ORBIT") : depths[1]),{"name":"chain","hash":{},"data":data,"loc":{"start":{"line":27,"column":31},"end":{"line":27,"column":93}}}))
    + "<br>\n					<b>Objects &gt; 1 cm</b> : "
    + alias2((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias3).call(alias1,"formatIntOrNA","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"OBJECT_1CM") : depths[1]),{"name":"chain","hash":{},"data":data,"loc":{"start":{"line":28,"column":32},"end":{"line":28,"column":85}}}))
    + "<br>\n					<b>Objects &gt; 10 cm</b> : "
    + alias2((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias3).call(alias1,"formatIntOrNA","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"OBJECT_10CM") : depths[1]),{"name":"chain","hash":{},"data":data,"loc":{"start":{"line":29,"column":33},"end":{"line":29,"column":87}}}))
    + "<br>\n					<b>Last Update</b> : "
    + alias2((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias3).call(alias1,"formatDate","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"CREATION_DATE") : depths[1]),{"name":"chain","hash":{},"data":data,"loc":{"start":{"line":30,"column":26},"end":{"line":30,"column":79}}}))
    + "<br>\n					<div class=\"btn-group\" role=\"group\" style=\"width:100%; display:flex;justify-content:center\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[2] != null ? lookupProperty(depths[2],"isFollowed") : depths[2]),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.program(8, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":32,"column":6},"end":{"line":40,"column":13}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    return "							<button type=\"button\" class=\"btn btn-primary followButton\" title=\"Unfollow event\" data-toggle=\"modal\" id=\"unfollow\">\n								<span class=\"fa fa-star\" aria-hidden=\"true\"></span>\n							</button>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "							<button type=\"button\" class=\"btn btn-default followButton\" title=\"Follow event\" data-toggle=\"modal\" id=\"follow\">\n								<span class=\"fa fa-star-o\" aria-hidden=\"true\"></span>\n							</button>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "	<center>\n		<button type=\"button\" class=\"btn btn-info uploadFileButton\" title=\"Upload File\" data-toggle=\"modal\" data-target=\"#uploadFile\" style=\"width:100%; color:#31708f; background-color:#d9edf7; border-color:#bce8f1\">\n			<span class=\"glyphicon glyphicon-upload\" aria-hidden=\"true\"></span> Upload File\n		</button>\n	</center>\n	<br>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<li>\n							<input class=\"detailFilesCheck\" type=\"checkbox\" name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":69,"column":61},"end":{"line":69,"column":70}}}) : helper)))
    + "\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":69,"column":79},"end":{"line":69,"column":88}}}) : helper)))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isChecked") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":69,"column":90},"end":{"line":69,"column":121}}})) != null ? stack1 : "")
    + ">\n								"
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":70,"column":8},"end":{"line":70,"column":17}}}) : helper)))
    + "\n							</input>\n						</li>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "checked";
},"15":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"nameSearchValue") || (depth0 != null ? lookupProperty(depth0,"nameSearchValue") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"nameSearchValue","hash":{},"data":data,"loc":{"start":{"line":77,"column":117},"end":{"line":77,"column":136}}}) : helper)))
    + "\" ";
},"17":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<div class=\"list-group-item list-group-item-arrow fileItem clickable\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":111,"column":77},"end":{"line":111,"column":87}}}) : helper)))
    + "\">\n				<span title=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":112,"column":17},"end":{"line":112,"column":25}}}) : helper)))
    + "\">\n					<div class=\"row\">\n						<div class=\"col-sm-3\">\n							<div class=\"row\">\n								<div class=\"col-sm-12\">\n									<center><span class=\"fa fa-"
    + alias4(((helper = (helper = lookupProperty(helpers,"icon") || (depth0 != null ? lookupProperty(depth0,"icon") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"icon","hash":{},"data":data,"loc":{"start":{"line":117,"column":36},"end":{"line":117,"column":44}}}) : helper)))
    + " fa-fw\"></span></center>\n								</div>\n								<div class=\"col-sm-12\">\n									<center><p class=\"list-group-item-text\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"type") || (depth0 != null ? lookupProperty(depth0,"type") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data,"loc":{"start":{"line":120,"column":49},"end":{"line":120,"column":57}}}) : helper)))
    + "</p></center>\n								</div>\n							</div>\n						</div>\n						<div class=\"col-sm-9\">\n							<div class=\"row\">\n								<div class=\"col-sm-12\" style=\"padding:0px\">\n									<h4 class=\"list-group-item-heading\" style=\"text-overflow: ellipsis; overflow: hidden; white-space: nowrap\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":127,"column":116},"end":{"line":127,"column":124}}}) : helper)))
    + "</h4>\n								</div>\n								<div class=\"col-sm-12\" style=\"padding:0px\">\n									<p class=\"list-group-item-text text-muted\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"subType") || (depth0 != null ? lookupProperty(depth0,"subType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"subType","hash":{},"data":data,"loc":{"start":{"line":130,"column":52},"end":{"line":130,"column":63}}}) : helper)))
    + "</p>\n								</div>\n							</div>\n						</div>\n					</div>\n				</span>\n			</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"panel panel-info\">\n	<div class=\"panel-heading clickable\" id=\"detailEventInfoPanelHeading\">\n		<div class=\"panel-title clearfix\">\n      <div class=\"pull-left truncate_text\" style=\"width: 90%\">\n  			<span class=\"glyphicon glyphicon-info-sign\"/>\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"eventsColumns") : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":5},"end":{"line":10,"column":14}}})) != null ? stack1 : "")
    + "      </div>\n			<div class=\"btn-group pull-right\" role=\"group\">\n				<div class=\"detailEventInfoPanel\"/>\n			</div>\n		</div>\n	</div>\n	<div id=\"panelBodyDetailEventInfo\" class=\"panel-collapse collapse\" style=\"-webkit-transition: none; transition: none\">\n		<div class=\"panel-body\">\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"eventsColumns") : depth0),{"name":"with","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":2},"end":{"line":42,"column":12}}})) != null ? stack1 : "")
    + "			</div>\n		</div>\n	</div>\n</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAdmin") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":0},"end":{"line":55,"column":7}}})) != null ? stack1 : "")
    + "\n<div class=\"detailFilesMenu\" style=\"height:"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"divHeight") || (depth0 != null ? lookupProperty(depth0,"divHeight") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"divHeight","hash":{},"data":data,"loc":{"start":{"line":57,"column":43},"end":{"line":57,"column":56}}}) : helper)))
    + "\">\n	<div class=\"input-group\">\n		<div class=\"input-group-btn\">\n			<div class=\"dropdown\">\n				<button class=\"btn btn-default dropdown-toggle\" type=\"button\" data-toggle=\"dropdown\">\n					<span class=\"glyphicon glyphicon-filter\"/>\n					<span class=\"caret\"></span>\n				</button>\n				<ul class=\"dropdown-menu detailFilesCheckList\" id=\"type\">\n					<h4>Type</h4>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"fileTypes") : depth0),{"name":"each","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":5},"end":{"line":73,"column":14}}})) != null ? stack1 : "")
    + "				</ul>\n			</div>\n		</div>\n		<input type=\"text\" class=\"form-control text\" id=\"name\" placeholder=\"Filter By Name\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"nameSearchValue") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":77,"column":86},"end":{"line":77,"column":145}}})) != null ? stack1 : "")
    + ">\n		<div class=\"input-group-btn\">\n			<div class=\"dropdown\">\n				<button class=\"btn btn-default dropdown-toggle\" type=\"button\" data-toggle=\"dropdown\">\n					<span class=\"glyphicon glyphicon-sort\"/>\n					<span class=\"caret\"></span>\n				</button>\n				<ul class=\"dropdown-menu dropdown-menu-right\">\n					<li>\n						<div class=\"sortItem clickable\" id=\"name-asc\">\n							Name (Ascending)\n						</div>\n					</li>\n					<li>\n						<div class=\"sortItem clickable\" id=\"name-desc\">\n							Name (Descending)\n						</div>\n					</li>\n					<li>\n						<div class=\"sortItem clickable\" id=\"type-asc\">\n							Type (Ascending)\n						</div>\n					</li>\n					<li>\n						<div class=\"sortItem clickable\" id=\"type-desc\">\n							Type (Descending)\n						</div>\n					</li>\n				</ul>\n			</div>\n		</div>\n	</div>\n	<div class=\"scroll-y-auto list-group\" style=\"height:85%\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"detailFiles") : depth0),{"name":"each","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":110,"column":2},"end":{"line":137,"column":11}}})) != null ? stack1 : "")
    + "	</div>\n</div>\n";
},"useData":true,"useDepths":true});
