/*
	Displays the filter menu to filter events objects.
*/
const Backbone = require('backbone')
const _ = require('lodash')
const $ = require('jquery')
const moment = require('moment')

const eventsFilterModel = require('../models/EventsFilterModel')
const eventsModel = require('../models/EventsModel')

const ChecklistInputView = require('../../../common/js/views/ChecklistInputView')
const TextInputView = require('../../../common/js/views/TextInputView')
const RangeInputView = require('../../../common/js/views/RangeInputView')
const TimeInputView = require('../../../common/js/views/TimeInputView')

const { eventsFieldNames } = require('../../../common/config/constants')

exports.EventsFilterView = Backbone.View.extend({
  template: require('../../templates/EventsFilterView.hbs'),

  eventsDisplayCollection: null,

  autoFocusField: null,

  isAdmin: false,

  initialize(options) {
    console.log('Initialized Events Filter View')

    // The design of the filtering is that the controls re-render when the
    // filters change. In order to avoid this, we keep a dataCollectionKey which
    // is tied to the unfiltered data. We will only re-render the controls
    // when this changes. At the moment this is just when the data first loads.
    //
    // See the checkDataCollection and render methods to see how they use these
    // variables.
    this.prevDataCollection = null
    this.dataCollectionKey = 0
    this.renderedDataCollectionKey = null

    this.eventsDataColleciton = eventsModel.eventsDataCollection
    this.listenTo(this.eventsDataColleciton, 'reset', this.checkDataCollection)

    this.eventsDisplayCollection = eventsModel.getDisplayCollection()
    this.listenTo(this.eventsDisplayCollection, 'reset', this.render)
  },

  events: {
    'click .remove-text-value': 'removeTextValue',
    'click .remove-time-value': 'removeTimeValue',
    'click input.check': 'applyCheckListFilter',
    'change input.range': 'applyRangeFilter',
    'input input.text': 'applyTextFilter',
    'changeDate .filter_datetime': 'applyTimeFilter',
    'click .filterFollow': 'applyFollowFilter',
    'click .filterRestricted': 'applyRestrictedFilter',
  },

  checkDataCollection(dataCollection) {
    dataCollection = dataCollection.toJSON()
    console.log('checking dataCollection', dataCollection)
    if (!_.isEqual(this.prevDataCollection, dataCollection)) {
      console.log('incrementing key to', this.dataCollectionKey)
      this.dataCollectionKey++
      this.prevDataCollection = dataCollection
    }
  },

  // render filter type specific views
  filterOptions(view, labelName, idName, options) {
    let tempView
    switch (view) {
      case 'text':
        tempView = new TextInputView({
          input: {
            label: labelName,
            id: idName,
            value: eventsFilterModel.has(idName)
              ? eventsFilterModel.get(idName).text
              : '',
          },
        })
        break

      case 'checkbox':
        tempView = new ChecklistInputView({
          input: {
            label: labelName,
            id: idName,
          },
          collection: eventsModel.getCheckList(options),
        })
        break

      case 'range':
        tempView = new RangeInputView({
          input: {
            label:
              eventsModel.getDisplayCollection().toJSON().length > 0
                ? labelName +
                  ' (' +
                  eventsModel.getMinRange(options) +
                  ' : ' +
                  eventsModel.getMaxRange(options) +
                  ')'
                : labelName,
            id: idName,
            valueMin: eventsModel.getMinValue(options),
            valueMax: eventsModel.getMaxValue(options),
            rangeMin: eventsModel.getMinRange(options),
            rangeMax: eventsModel.getMaxRange(options),
            rangeFilterShow:
              eventsModel.getDisplayCollection().toJSON().length > 0,
          },
        })
        break

      case 'time':
        tempView = new TimeInputView({
          input: {
            label: labelName,
            id: idName,
            valueFrom: eventsFilterModel.has(idName + '-From')
              ? moment
                  .unix(eventsFilterModel.get(idName + '-From').dateFrom / 1000)
                  .utc()
                  .format('DD-MMM-YYYY')
              : '',
            valueTo: eventsFilterModel.has(idName + '-To')
              ? moment
                  .unix(eventsFilterModel.get(idName + '-To').dateTo / 1000)
                  .utc()
                  .format('DD-MMM-YYYY')
              : '',
          },
        })
        break
    }

    return tempView
  },

  render() {
    if (this.renderedDataCollectionKey === this.dataCollectionKey) {
      return
    }
    this.renderedDataCollectionKey = this.dataCollectionKey

    const filterMarkup = this.template({
      isFollowedFilterApplied: eventsFilterModel.has('followedFilter'),
    })

    const container = document.createDocumentFragment()
    const div = document.createElement('div')
    div.innerHTML = filterMarkup
    container.appendChild(div.childNodes[0])

    const filter = document.createDocumentFragment()
    const { filterOptions } = this

    // Append the following filters
    const children = [
      filterOptions('text', 'Name', eventsFieldNames.NAME, ''),
      filterOptions('time', 'Event Date', eventsFieldNames.EVENT_DATE, ''),
      filterOptions(
        'checkbox',
        'Event Type',
        eventsFieldNames.EVENT_TYPE,
        eventsFieldNames.EVENT_TYPE,
      ),
      filterOptions('text', 'COSPAR ID', eventsFieldNames.OBJECT_COSPAR_ID, ''),
      filterOptions(
        'range',
        'Catalogued Objects',
        eventsFieldNames.CATALOGUED,
        eventsFieldNames.CATALOGUED,
      ),
      filterOptions(
        'range',
        'Objects On Orbit',
        eventsFieldNames.CATALOGUED_ON_ORBIT,
        eventsFieldNames.CATALOGUED_ON_ORBIT,
      ),
      filterOptions(
        'range',
        'Objects > 1 cm',
        eventsFieldNames.OBJECT_1CM,
        eventsFieldNames.OBJECT_1CM,
      ),
      filterOptions(
        'range',
        'Objects > 10 cm',
        eventsFieldNames.OBJECT_10CM,
        eventsFieldNames.OBJECT_10CM,
      ),
      filterOptions('time', 'Last Update', eventsFieldNames.CREATION_DATE, ''),
    ]

    children.forEach((child) => filter.appendChild(child.render().el))
    container.querySelector('.filter-properties').appendChild(filter)

    this.$el.html(container)

    // Autofocus if last filter was text search
    if (this.autoFocusField !== null) {
      this.el.querySelector('#' + this.autoFocusField).focus()
    }
    return this
  },

  applyCheckListFilter(e) {
    console.log('CheckList clicked', e)
    this.autoFocusField = null
    var inputGroup = $(e.target).parents('div.input-group')
    var input = inputGroup.children('input.value')
    var inputValues = []
    inputGroup.find('input.check').each(function (index) {
      var checked = $(this).prop('checked')
      if (!checked) {
        inputValues.push($(this).prop('value'))
      }
    })
    eventsFilterModel.set($(e.target).parents('div.input-group').prop('id'), {
      checkbox: inputValues,
    })
  },

  applyRangeFilter(e) {
    console.log('Range clicked', e)
    this.autoFocusField = null
    eventsFilterModel.set(e.currentTarget.id, { range: e.currentTarget.value })
  },

  applyTextFilter(e) {
    this.autoFocusField = e.currentTarget.id
    console.log('Text searched', e)
    eventsFilterModel.set(e.currentTarget.id, { text: e.currentTarget.value })
  },

  removeTextValue(e) {
    console.log('Remove Text Value', e)
    const { targetId } = e.currentTarget.dataset

    this.$el.find(`#${targetId}`).val('')
    eventsFilterModel.set(targetId, { text: '' })
  },

  applyTimeFilter(e) {
    console.log('Time clicked', e)
    this.autoFocusField = null
    var id = e.currentTarget.id
    var field = id.split('-')[1]
    var fromTo = id.split('-')[0]
    switch (fromTo) {
      case 'from':
        this.el.querySelector('#text-' + id).value = moment
          .utc(e.date)
          .format('DD-MMM-YYYY')
        eventsFilterModel.set(field + '-From', {
          dateFrom: moment.utc(e.date).format('x'),
        })
        break
      case 'to':
        this.el.querySelector('#text-' + id).value = moment
          .utc(e.date)
          .format('DD-MMM-YYYY')
        eventsFilterModel.set(field + '-To', {
          dateTo: moment.utc(e.date).format('x'),
        })
        break
    }
  },

  applyFollowFilter(e) {
    console.log('Follow Filter clicked', e)
    if (eventsFilterModel.has('followedFilter')) {
      eventsFilterModel.unset('followedFilter')
      this.$el.find(`#filterFollow`).removeClass('active')
    } else {
      eventsFilterModel.set('followedFilter', true)
      this.$el.find(`#filterFollow`).addClass('active')
    }
  },

  applyRestrictedFilter(e) {
    console.log('Restricted Filter clicked', e)
    if (eventsFilterModel.has('restrictedFilter')) {
      eventsFilterModel.unset('restrictedFilter')
    } else {
      eventsFilterModel.set('restrictedFilter', true)
    }
  },

  removeTimeValue(e) {
    console.log('Remove Time clicked', e)
    this.autoFocusField = null
    var id = e.currentTarget.id
    var field = id.split('-')[1]
    var fromTo = id.split('-')[0]
    switch (fromTo) {
      case 'removeFrom':
        this.el.querySelector('#text-from-' + field).value = ''
        eventsFilterModel.unset(field + '-From')
        break
      case 'removeTo':
        this.el.querySelector('#text-to-' + field).value = ''
        eventsFilterModel.unset(field + '-To')
        break
    }
  },
})
