/*
	Model/collection to fetch event objects from server.
*/
var Backbone = require('backbone')

var EventsSimulationModel = Backbone.Model.extend({
  urlRoot: '/api/protected/eventsSimulation',
  idAttribute: 'messageId',
})

var EventsSimulationCollection = Backbone.Collection.extend({
  model: EventsSimulationModel,
  url() {
    return '/api/protected/eventsSimulation/' + this.id
  },

  initialize(options) {
    this.id = options.id
    this.fetch({ reset: true })
  },
})

module.exports = EventsSimulationCollection
