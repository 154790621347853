/*
	Model/collection to get list of users in fragmentation tool
*/
var Backbone = require('backbone')

var UserModel = Backbone.Model.extend({
  urlRoot: '/api/protected/users',
  idAttribute: 'userId',
})

var UserCollection = Backbone.Collection.extend({
  model: UserModel,
  url: '/api/protected/users',

  initialize() {
    this.fetch({ reset: true })
  },

  parse(response) {
    return response
  },
})

module.exports = UserCollection
