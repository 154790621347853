var Backbone = require('backbone')
var _ = require('lodash')
var moment = require('moment')
var $ = require('jquery')

var view = (module.exports = Backbone.View.extend({
  template: require('../../templates/TimeInputView.hbs'),

  initialize(options) {
    this.input = options.input
  },

  render() {
    this.$el.html(this.template(this.input))

    this.$el.find('.filter_datetime').datetimepicker({
      todayBtn: 1,
      autoclose: 1,
      todayHighlight: 1,
      format: 'yyyy-mm-dd',
      minView: 2,
    })

    return this
  },

  loadFromFilter(inFilterSet) {
    var i
    for (i = 0; i < inFilterSet.length; ++i) {
      if (inFilterSet[i].hasOwnProperty(this.input.id)) {
        console.error('TODO FOUND IT!', this.input.id)
      }
    }
  },

  getFilter() {
    var inp = this.el.querySelector('.field')
    var selectedButton = inp.parentNode.querySelector('button.selected')
    var selector = ''
    if (selectedButton) {
      if (selectedButton.childNodes.length > 1) {
        selector = selectedButton.querySelector(':not(.hidden)').innerHTML
      } else {
        selector = selectedButton.innerHTML
      }
    }

    var value = inp.value.trim()
    var dbCol = inp.id.split('-')[1]
    var from = moment.utc()
    var to = moment.utc()

    var ret = null
    if (value !== '') {
      ret = {}
      var toInput = this.el.querySelector('#field-' + dbCol + '-end')
      from = moment.utc($(inp).datetimepicker('getUTCDate'))
      to = moment.utc($(toInput).datetimepicker('getUTCDate'))
      ret[dbCol] = {
        $lt: '$date(' + to.valueOf() + ')',
        $gt: '$date(' + from.valueOf() + ')',
      }
    }
    return ret
  },
}))
