// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "						<th class=\"col-size-history-detail\">\n							<span class=\"word-hyphenate\">Creation Date</span>\n						</th>\n						<th class=\"col-size-history-detail\">\n							<span class=\"word-hyphenate\">Catalogued Objects</span>\n						</th>\n						<th class=\"col-size-history-detail\">\n							<span class=\"word-hyphenate\">Object on Orbit</span>\n						</th>\n						<th class=\"col-size-history-detail\">\n							<span class=\"word-hyphenate\">Objects &gt; 1 cm</span>\n						</th>\n						<th class=\"col-size-history-detail\">\n							<span class=\"word-hyphenate\">Objects &gt; 10 cm</span>\n						</th>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<tr class=\"col-size-row\">\n						<th class=\"col-size-history-detail\" />\n						<th class=\"col-size-history-detail-extra\">\n							<span class=\"word-hyphenate\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"object1CosparId") || (depth0 != null ? lookupProperty(depth0,"object1CosparId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"object1CosparId","hash":{},"data":data,"loc":{"start":{"line":28,"column":36},"end":{"line":28,"column":55}}}) : helper)))
    + "</span>\n						</th>\n						<th class=\"col-size-history-detail-extra\">\n							<span class=\"word-hyphenate\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"object2CosparId") || (depth0 != null ? lookupProperty(depth0,"object2CosparId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"object2CosparId","hash":{},"data":data,"loc":{"start":{"line":31,"column":36},"end":{"line":31,"column":55}}}) : helper)))
    + "</span>\n						</th>\n						<th class=\"col-size-history-detail-extra\">\n							<span class=\"word-hyphenate\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"object1CosparId") || (depth0 != null ? lookupProperty(depth0,"object1CosparId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"object1CosparId","hash":{},"data":data,"loc":{"start":{"line":34,"column":36},"end":{"line":34,"column":55}}}) : helper)))
    + "</span>\n						</th>\n						<th class=\"col-size-history-detail-extra\">\n							<span class=\"word-hyphenate\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"object2CosparId") || (depth0 != null ? lookupProperty(depth0,"object2CosparId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"object2CosparId","hash":{},"data":data,"loc":{"start":{"line":37,"column":36},"end":{"line":37,"column":55}}}) : helper)))
    + "</span>\n						</th>\n						<th class=\"col-size-history-detail-extra\">\n							<span class=\"word-hyphenate\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"object1CosparId") || (depth0 != null ? lookupProperty(depth0,"object1CosparId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"object1CosparId","hash":{},"data":data,"loc":{"start":{"line":40,"column":36},"end":{"line":40,"column":55}}}) : helper)))
    + "</span>\n						</th>\n						<th class=\"col-size-history-detail-extra\">\n							<span class=\"word-hyphenate\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"object2CosparId") || (depth0 != null ? lookupProperty(depth0,"object2CosparId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"object2CosparId","hash":{},"data":data,"loc":{"start":{"line":43,"column":36},"end":{"line":43,"column":55}}}) : helper)))
    + "</span>\n						</th>\n						<th class=\"col-size-history-detail-extra\">\n							<span class=\"word-hyphenate\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"object1CosparId") || (depth0 != null ? lookupProperty(depth0,"object1CosparId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"object1CosparId","hash":{},"data":data,"loc":{"start":{"line":46,"column":36},"end":{"line":46,"column":55}}}) : helper)))
    + "</span>\n						</th>\n						<th class=\"col-size-history-detail-extra\">\n							<span class=\"word-hyphenate\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"object2CosparId") || (depth0 != null ? lookupProperty(depth0,"object2CosparId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"object2CosparId","hash":{},"data":data,"loc":{"start":{"line":49,"column":36},"end":{"line":49,"column":55}}}) : helper)))
    + "</span>\n						</th>\n					</tr>\n";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"collection") : depths[1]),{"name":"each","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":5},"end":{"line":76,"column":14}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<tr class=\"col-size-row\">\n							<td class=\"col-size-history-detail word-hyphenate\">"
    + container.escapeExpression((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||container.hooks.helperMissing).call(alias1,"formatDate","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"CREATION_DATE") : depths[1]),{"name":"chain","hash":{},"data":data,"loc":{"start":{"line":59,"column":58},"end":{"line":59,"column":111}}}))
    + "</td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"lookup").call(alias1,depth0,(depths[1] != null ? lookupProperty(depths[1],"OBJECT2_COSPAR_ID") : depths[1]),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":60,"column":13},"end":{"line":60,"column":47}}}),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.program(9, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":60,"column":7},"end":{"line":74,"column":14}}})) != null ? stack1 : "")
    + "						</tr>\n";
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "								<td class=\"col-size-history-detail-extra word-hyphenate\">"
    + alias3((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias2).call(alias1,"formatIntOrNA","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"OBJECT1_CATALOGUED") : depths[1]),{"name":"chain","hash":{},"data":data,"loc":{"start":{"line":61,"column":65},"end":{"line":61,"column":126}}}))
    + "</td>\n								<td class=\"col-size-history-detail-extra word-hyphenate\">"
    + alias3((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias2).call(alias1,"formatIntOrNA","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"OBJECT2_CATALOGUED") : depths[1]),{"name":"chain","hash":{},"data":data,"loc":{"start":{"line":62,"column":65},"end":{"line":62,"column":126}}}))
    + "</td>\n								<td class=\"col-size-history-detail-extra word-hyphenate\">"
    + alias3((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias2).call(alias1,"formatIntOrNA","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"OBJECT1_CATALOGUED_ON_ORBIT") : depths[1]),{"name":"chain","hash":{},"data":data,"loc":{"start":{"line":63,"column":65},"end":{"line":63,"column":135}}}))
    + "</td>\n								<td class=\"col-size-history-detail-extra word-hyphenate\">"
    + alias3((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias2).call(alias1,"formatIntOrNA","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"OBJECT2_CATALOGUED_ON_ORBIT") : depths[1]),{"name":"chain","hash":{},"data":data,"loc":{"start":{"line":64,"column":65},"end":{"line":64,"column":135}}}))
    + "</td>\n								<td class=\"col-size-history-detail-extra word-hyphenate\">"
    + alias3((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias2).call(alias1,"formatIntOrNA","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"OBJECT1_1CM") : depths[1]),{"name":"chain","hash":{},"data":data,"loc":{"start":{"line":65,"column":65},"end":{"line":65,"column":119}}}))
    + "</td>\n								<td class=\"col-size-history-detail-extra word-hyphenate\">"
    + alias3((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias2).call(alias1,"formatIntOrNA","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"OBJECT2_1CM") : depths[1]),{"name":"chain","hash":{},"data":data,"loc":{"start":{"line":66,"column":65},"end":{"line":66,"column":119}}}))
    + "</td>\n								<td class=\"col-size-history-detail-extra word-hyphenate\">"
    + alias3((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias2).call(alias1,"formatIntOrNA","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"OBJECT1_10CM") : depths[1]),{"name":"chain","hash":{},"data":data,"loc":{"start":{"line":67,"column":65},"end":{"line":67,"column":120}}}))
    + "</td>\n								<td class=\"col-size-history-detail-extra word-hyphenate\">"
    + alias3((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias2).call(alias1,"formatIntOrNA","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"OBJECT2_10CM") : depths[1]),{"name":"chain","hash":{},"data":data,"loc":{"start":{"line":68,"column":65},"end":{"line":68,"column":120}}}))
    + "</td>\n";
},"9":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "								<td class=\"col-size-history-detail word-hyphenate\">"
    + alias3((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias2).call(alias1,"formatIntOrNA","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"CATALOGUED") : depths[1]),{"name":"chain","hash":{},"data":data,"loc":{"start":{"line":70,"column":59},"end":{"line":70,"column":112}}}))
    + "</td>\n								<td class=\"col-size-history-detail word-hyphenate\">"
    + alias3((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias2).call(alias1,"formatIntOrNA","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"CATALOGUED_ON_ORBIT") : depths[1]),{"name":"chain","hash":{},"data":data,"loc":{"start":{"line":71,"column":59},"end":{"line":71,"column":121}}}))
    + "</td>\n								<td class=\"col-size-history-detail word-hyphenate\">"
    + alias3((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias2).call(alias1,"formatIntOrNA","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"OBJECT_1CM") : depths[1]),{"name":"chain","hash":{},"data":data,"loc":{"start":{"line":72,"column":59},"end":{"line":72,"column":112}}}))
    + "</td>\n								<td class=\"col-size-history-detail word-hyphenate\">"
    + alias3((lookupProperty(helpers,"chain")||(depth0 && lookupProperty(depth0,"chain"))||alias2).call(alias1,"formatIntOrNA","lookup",depth0,(depths[1] != null ? lookupProperty(depths[1],"OBJECT_10CM") : depths[1]),{"name":"chain","hash":{},"data":data,"loc":{"start":{"line":73,"column":59},"end":{"line":73,"column":113}}}))
    + "</td>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"eventsCollection\">\n	<div class=\"eventsTable\">\n		<table class=\"table table-striped table-hover head-fixed\">\n			<thead>\n				<tr class=\"col-size-row\">\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"eventsColumns") : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":5},"end":{"line":22,"column":14}}})) != null ? stack1 : "")
    + "				</tr>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"object2CosparId") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":4},"end":{"line":52,"column":11}}})) != null ? stack1 : "")
    + "				<tr class=\"col-size-row\" style=\"border-bottom-style: solid\" />\n			</thead>\n			<tbody style=\"height:"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"divHeight") || (depth0 != null ? lookupProperty(depth0,"divHeight") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"divHeight","hash":{},"data":data,"loc":{"start":{"line":55,"column":24},"end":{"line":55,"column":37}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"eventsColumns") : depth0),{"name":"with","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":4},"end":{"line":77,"column":13}}})) != null ? stack1 : "")
    + "			</tbody>\n		</table>\n	</div>\n</div>\n";
},"useData":true,"useDepths":true});
