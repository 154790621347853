// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return " active ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row filter-properties\">\n	<div class=\"btn-group eventAccess\" role=\"group\" style=\"width:100%;display:flex;justify-content:center\">\n		<button type=\"button\" id=\"filterFollow\" class=\"btn btn-sm btn-default filterFollow "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isFollowedFilterApplied") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":85},"end":{"line":3,"column":131}}})) != null ? stack1 : "")
    + "\" title=\"Event Followed\">\n			<span class=\"fa fa-star fa-fw\"></span>\n		</button>\n	</div>\n	<br>\n</div>\n";
},"useData":true});
