module.exports={
  "eventsFieldNames": {
    "CREATION_DATE": "creationDate",
    "CREATION_DATE_RANK": "creationDateRank",
    "IS_FOLLOWED": "isFollowed",
    "FRAGMENTATION_ID": "fragmentationId",
    "EVENT_ID": "fragmentationId",
    "OBJECT1_NAME": "object1Name",
    "OBJECT2_NAME": "object2Name",
    "EVENT_DATE": "timeOfEvent",
    "EVENT_TYPE": "type_of_event",
    "OBJECT1_COSPAR_ID": "object1CosparId",
    "OBJECT2_COSPAR_ID": "object2CosparId",
    "OBJECT1_CATALOGUED": "object1NumberOfFragments",
    "OBJECT2_CATALOGUED": "object2NumberOfFragments",
    "OBJECT1_CATALOGUED_ON_ORBIT": "object1NumberOfFragmentsOnOrbit",
    "OBJECT2_CATALOGUED_ON_ORBIT": "object2NumberOfFragmentsOnOrbit",
    "OBJECT1_1CM": "object1Fragments1cm",
    "OBJECT2_1CM": "object2Fragments1cm",
    "OBJECT1_10CM": "object1Fragments10cm",
    "OBJECT2_10CM": "object2Fragments10cm",
    "OBJECT1_BUSTER_ID": "object1BusterId",
    "OBJECT2_BUSTER_ID": "object2BusterId",
    "BUSTER_ID": "busterId",
    "NAME": "objectName",
    "OBJECT_COSPAR_ID": "objectCosparId",
    "CATALOGUED": "objectNumberOfFragments",
    "CATALOGUED_ON_ORBIT": "objectNumberOfFragmentsOnOrbit",
    "OBJECT_1CM": "objectFragments1cm",
    "OBJECT_10CM": "objectFragments10cm",
    "ALTITUDE": "altitude",
    "EPOCH": "epoch",
    "OBJECT1_OBJECT2_JOINER": "/",
    "UNDEFINED": "UNDEFINED"
  }
}
