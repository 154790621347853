/*
	Model/collection to get list of users who sent new messages
*/
var Backbone = require('backbone')

var AskAdminUserModel = Backbone.Model.extend({
  urlRoot: '/api/protected/askAdmin/messageThreads',
  idAttribute: 'userId',
})

var AskAdminUserCollection = Backbone.Collection.extend({
  model: AskAdminUserModel,
  url() {
    let url = '/api/protected/askAdmin/messageThreads'
    if (this.eventId !== null) {
      url += `?eventId=${this.eventId}`
    }
    return url
  },

  initialize(options) {
    this.eventId = options.eventId
    this.fetch({ reset: true })
  },

  parse(response) {
    return response
  },
})

module.exports = AskAdminUserCollection
