/*
	Model/collection to fetch components of home view
*/
const Backbone = require('backbone')

const HomeModel = Backbone.Model.extend({
  urlRoot: '/api/home',
  idAttribute: 'messageId',
})

const HomeCollection = Backbone.Collection.extend({
  model: HomeModel,
  url: '/api/home',

  parse(response) {
    return response
  },
})

module.exports = HomeCollection
