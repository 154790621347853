var Backbone = require('backbone')
var _ = require('lodash')
var Slider = require('bootstrap-slider')

var view = (module.exports = Backbone.View.extend({
  template: require('../../templates/RangeInput.hbs'),

  initialize(options) {
    this.input = options.input
  },

  render() {
    this.input.slider_step = (this.input.rangeMax - this.input.rangeMin) / 100.0
    this.$el.html(this.template(this.input))
    //var slider = new Slider(this.input.id, {});
    this.$el.find('#' + this.input.id).slider({})
    //new Slider("#"+this.input.id, { min: 0, max: 10, value: [0, 10], focus: true });
    return this
  },

  loadFromFilter(inFilterSet) {
    var domElement, value, i

    domElement = this.el.querySelector('.field')
    //console.error(checkboxes);
    for (i = 0; i < inFilterSet.length; ++i) {
      if (inFilterSet[i].hasOwnProperty(this.input.id)) {
        value = inFilterSet[i][this.input.id]
        if (value.$in) {
          value = value.$in.join(';')
        }

        domElement.value = value

        return
      }
    }

    domElement.value = ''
  },

  getFilter() {
    var inp = this.el.querySelector('.field')
    var value = inp.value.trim()
    var dbCol = inp.id.split('-')[1]
    var values = value.split(';')

    var ret = null
    if (value !== '') {
      ret = {}
      if (values.length > 1) {
        ret[dbCol] = { $in: values }
      } else {
        ret[dbCol] = value
      }
    }

    return ret
  },
}))
