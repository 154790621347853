/*
	Model to store admin event filter attributes.
*/
var Backbone = require('backbone')

var AdminEventFilterModel = Backbone.Model.extend({})

var adminEventFilterModel = new AdminEventFilterModel()

// make it a singleton
module.exports = adminEventFilterModel
