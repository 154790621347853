// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\">\n	<div class=\"col-md-12\">\n		<div class=\"xhr-error\">\n			<h1>Ooops!</h1>\n			<h2>Error: "
    + alias4(((helper = (helper = lookupProperty(helpers,"status") || (depth0 != null ? lookupProperty(depth0,"status") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"status","hash":{},"data":data,"loc":{"start":{"line":5,"column":14},"end":{"line":5,"column":24}}}) : helper)))
    + " - "
    + alias4(((helper = (helper = lookupProperty(helpers,"statusText") || (depth0 != null ? lookupProperty(depth0,"statusText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"statusText","hash":{},"data":data,"loc":{"start":{"line":5,"column":27},"end":{"line":5,"column":41}}}) : helper)))
    + "</h2>\n			<p>"
    + alias4(((helper = (helper = lookupProperty(helpers,"responseText") || (depth0 != null ? lookupProperty(depth0,"responseText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"responseText","hash":{},"data":data,"loc":{"start":{"line":6,"column":6},"end":{"line":6,"column":22}}}) : helper)))
    + "</p>\n		</div>\n	</div>\n</div>\n";
},"useData":true});
