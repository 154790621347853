/*
	Admin View - View to display user settings, ask admin and event info for a given event and user
*/

const Backbone = require('backbone')

let { AskAdminView } = require('../../../common/js/views/AskAdminView')

const eventsColumns =
  require('../../../common/config/constants').eventsFieldNames

exports.UserEventItemView = Backbone.View.extend({
  template: require('../../templates/UserEventItemView.hbs'),

  divHeight: 0,

  askAdminCollection: null,

  initialize(options) {
    console.log('Initialized User Event View')

    this.router = options.router

    this.eventId = options.eventId
    this.eventInfo = options.eventInfo
    this.userInfo = options.userInfo
    this.userSettings = options.userSettings
  },

  events: {
    'click button.eventDetails': 'eventDetails',
    'click button.saveUserSettings': 'saveUserSettings',
  },

  renderAskAdmin() {
    if (this.el.querySelector('.askAdminWindow-admin')) {
      this.$el.find('.askAdminWindow-admin').remove()
    }
    this.el
      .querySelector('.selectionMessageBox')
      .appendChild(this.askAdminView.render(true).el)
    return this
  },

  render(divHeight) {
    this.divHeight = divHeight
    const isDataAvailable = !(
      typeof this.eventInfo === 'undefined' ||
      typeof this.userInfo === 'undefined'
    )
    const isEventInfoDisplay = this.eventInfo.cosparId !== 'Generic'

    const markup = this.template({
      divHeightEventPanelBody: this.divHeight - 110 + 'px',
      divHeightUserPanelBody: this.divHeight - 510 + 'px',
      eventInfo: this.eventInfo,
      userInfo: this.userInfo,
      isDataAvailable,
      isEventInfoDisplay,
      eventsColumns,
      userSettings: this.userSettings.toJSON()[0],
      isUserSettingsGeneric: this.eventId === '-1',
      daily: 'Daily',
      weekly: 'Weekly',
      never: 'Never',
    })
    this.$el.html(markup)

    const eventId = this.eventId === '-1' ? null : this.eventId

    if (isDataAvailable) {
      this.askAdminView = new AskAdminView({
        eventId,
        emailUser: this.userInfo.email,
        isAdminUser: true,
        view: 'admin',
      })
      this.renderAskAdmin()
    }

    return this
  },

  eventDetails(e) {
    this.router.navigate('events/' + this.eventId, { trigger: true })
  },

  saveUserSettings(e) {
    const isUserSettingsGeneric = this.eventId === '-1'

    const saveObj = {}

    if (isUserSettingsGeneric) {
      // Generic settings changed
      let newFrequencyEmail = 'Never'
      if (this.el.querySelector('#frequencyEmailDaily').checked) {
        newFrequencyEmail = 'Daily'
      } else if (this.el.querySelector('#frequencyEmailWeekly').checked) {
        newFrequencyEmail = 'Weekly'
      } else {
        newFrequencyEmail = 'Never'
      }

      saveObj.frequencyEmail = newFrequencyEmail
    } else {
      saveObj.isFollowed = !!this.el.querySelector('#eventFollowedTrue').checked
    }

    const collection = this.userSettings
    this.userSettingsModel = new collection.model()

    this.userSettingsModel.set({
      saveObj,
      eventId: this.eventId,
      userId: this.userInfo.email,
    })
    this.userSettingsModel.idAttribute = null
    this.userSettingsModel.save()
  },
})
