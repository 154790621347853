// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "		<div class=\"btn-group\" role=\"group\" style=\"margin-bottom:10px\">\n			<button type=\"button\" class=\"btn btn-default btn-sm back\" title=\"Back to user list\">\n				<span class=\"glyphicon glyphicon-circle-arrow-left\" style=\"color: #EC916A\" />\n			</button>\n		</div>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<li class=\"clearfix border-radius-5\" style="
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAdminMessage") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.program(6, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":11,"column":46},"end":{"line":11,"column":137}}})) != null ? stack1 : "")
    + ">\n				<div class=\"chat-body clearfix\" style=\"margin-left:5px;margin-right:5px\">\n					<div>\n						<small class=\"text-muted\">\n							<span class=\"glyphicon glyphicon-time\"> </span>\n							"
    + alias3(((helper = (helper = lookupProperty(helpers,"messageTimeStamp") || (depth0 != null ? lookupProperty(depth0,"messageTimeStamp") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"messageTimeStamp","hash":{},"data":data,"loc":{"start":{"line":16,"column":7},"end":{"line":16,"column":27}}}) : helper)))
    + "\n						</small>\n						<strong class=\"pull-right\">\n"
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"isAdminMessage") : depth0),(depths[1] != null ? lookupProperty(depths[1],"isAdminUser") : depths[1]),{"name":"ifCond","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.program(10, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":19,"column":8},"end":{"line":27,"column":19}}})) != null ? stack1 : "")
    + "						</strong>\n					</div>\n					<div style=\"word-wrap: break-word\">\n						"
    + alias3((lookupProperty(helpers,"breaklines")||(depth0 && lookupProperty(depth0,"breaklines"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"body") : depth0),{"name":"breaklines","hash":{},"data":data,"loc":{"start":{"line":31,"column":6},"end":{"line":31,"column":25}}}))
    + "\n					</div>\n				</div>\n			</li>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "\"background-color: #d9edf7\"";
},"6":function(container,depth0,helpers,partials,data) {
    return "\"background-color: #fcf8e3\"";
},"8":function(container,depth0,helpers,partials,data) {
    return "								 you\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isAdminMessage") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":22,"column":9},"end":{"line":26,"column":16}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    return "									 admin\n";
},"13":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "									 "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data,"loc":{"start":{"line":25,"column":10},"end":{"line":25,"column":18}}}) : helper)))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"askAdminBody-"
    + alias4(((helper = (helper = lookupProperty(helpers,"view") || (depth0 != null ? lookupProperty(depth0,"view") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"view","hash":{},"data":data,"loc":{"start":{"line":1,"column":25},"end":{"line":1,"column":33}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"backButtonDisplay") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":1},"end":{"line":8,"column":8}}})) != null ? stack1 : "")
    + "	<ul class=\"chat askAdminChatBox-"
    + alias4(((helper = (helper = lookupProperty(helpers,"view") || (depth0 != null ? lookupProperty(depth0,"view") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"view","hash":{},"data":data,"loc":{"start":{"line":9,"column":33},"end":{"line":9,"column":41}}}) : helper)))
    + "\" style=\"height:"
    + alias4(((helper = (helper = lookupProperty(helpers,"divHeightPanelBody") || (depth0 != null ? lookupProperty(depth0,"divHeightPanelBody") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"divHeightPanelBody","hash":{},"data":data,"loc":{"start":{"line":9,"column":57},"end":{"line":9,"column":79}}}) : helper)))
    + "; overflow-y:auto\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"collection") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":2},"end":{"line":35,"column":11}}})) != null ? stack1 : "")
    + "	</ul>\n	<div class=\"form-group\" style=\"padding-top: 6px\">\n		<div class=\"col-lg-10\" style=\"padding-left: 0px; padding-right: 0px; padding-top: 0px; padding-bottom: 0px\">\n			<textarea class = \"form-control noresize\" rows=\"3\" placeholder=\"Enter Message\" id=\"askAdminMessage-"
    + alias4(((helper = (helper = lookupProperty(helpers,"view") || (depth0 != null ? lookupProperty(depth0,"view") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"view","hash":{},"data":data,"loc":{"start":{"line":39,"column":102},"end":{"line":39,"column":110}}}) : helper)))
    + "\"></textarea>\n		</div>\n		<div class=\"col-lg-2\" style=\"padding-left: 0px; padding-right: 0px; padding-top: 0px; padding-bottom: 0px\">\n			<div class=\"btn-group-vertical\" role=\"group\" style=\"min-width:100%\">\n				<button class=\"btn btn-sm btn-primary sendMessage\" title=\"Send Message\" style=\"padding-top: 8px; padding-bottom: 8px\">\n					<span class=\"glyphicon glyphicon-send\"/>\n				</button>\n				<button class=\"btn btn-sm btn-default eraseMessage\" title=\"Clear Message\" style=\"padding-top: 8px; padding-bottom: 8px\">\n					<span class=\"glyphicon glyphicon-erase\"/>\n				</button>\n			</div>\n		</div>\n	</div>\n</div>\n";
},"useData":true,"useDepths":true});
