// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"col-lg-2\">\n      <div class=\"input-group\">\n        <div class=\"input-group-btn\">\n          <div class=\"dropdown\">\n            <button class=\"btn btn-default dropdown-toggle\" type=\"button\" data-toggle=\"dropdown\">\n              <span class=\"glyphicon glyphicon-filter\"/>\n              <span class=\"caret\"></span>\n            </button>\n            <ul class=\"dropdown-menu emailFilter\" id=\"type\">\n              <h4>Domain</h4>\n              <form class=\"domainListCheckForm\" id=\"user-domain\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"userDomainCheckBox") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":16},"end":{"line":20,"column":25}}})) != null ? stack1 : "")
    + "              </form>\n            </ul>\n          </div>\n        </div>\n        <input type=\"text\" class=\"form-control text\" id=\"user-email\" placeholder=\"Filter By User Email\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"emailSearchValue") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":104},"end":{"line":25,"column":165}}})) != null ? stack1 : "")
    + ">\n      </div>\n      <div class=\"scroll-y-auto list-group\" style=\"height:"
    + alias4(((helper = (helper = lookupProperty(helpers,"divHeight") || (depth0 != null ? lookupProperty(depth0,"divHeight") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"divHeight","hash":{},"data":data,"loc":{"start":{"line":27,"column":58},"end":{"line":27,"column":71}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"userList") : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":8},"end":{"line":36,"column":17}}})) != null ? stack1 : "")
    + "      </div>\n    </div>\n    <div class=\"col-lg-2\">\n      <input type=\"text\" class=\"form-control text\" id=\"event-eventId\" placeholder=\"Filter By COSPAR ID\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"eventSearchValue") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":104},"end":{"line":40,"column":165}}})) != null ? stack1 : "")
    + ">\n      <div class=\"scroll-y-auto list-group\" style=\"height:"
    + alias4(((helper = (helper = lookupProperty(helpers,"divHeight") || (depth0 != null ? lookupProperty(depth0,"divHeight") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"divHeight","hash":{},"data":data,"loc":{"start":{"line":41,"column":58},"end":{"line":41,"column":71}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"eventsColumns") : depth0),{"name":"with","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":8},"end":{"line":53,"column":17}}})) != null ? stack1 : "")
    + "      </div>\n    </div>\n    <div class=\"col-lg-8\">\n      <div class=\"selectionItem\" />\n    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <li>\n                    <input class=\"domainListCheck\" type=\"checkbox\" name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":16,"column":73},"end":{"line":16,"column":82}}}) : helper)))
    + "\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":16,"column":91},"end":{"line":16,"column":100}}}) : helper)))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isChecked") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":102},"end":{"line":16,"column":133}}})) != null ? stack1 : "")
    + ">\n                      "
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":17,"column":22},"end":{"line":17,"column":31}}}) : helper)))
    + "\n                    </input>\n                  </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "checked";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"emailSearchValue") || (depth0 != null ? lookupProperty(depth0,"emailSearchValue") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"emailSearchValue","hash":{},"data":data,"loc":{"start":{"line":25,"column":136},"end":{"line":25,"column":156}}}) : helper)))
    + "\" ";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div\n            class=\"list-group-item usersEmailListItem clickable\"\n            id=\"usersEmailListItem-"
    + alias1(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"index","hash":{},"data":data,"loc":{"start":{"line":31,"column":35},"end":{"line":31,"column":45}}}) : helper)))
    + "\"\n            style=\"overflow: hidden; text-overflow: ellipsis;\"\n          >\n            "
    + alias1(container.lambda(depth0, depth0))
    + "\n          </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"eventSearchValue") || (depth0 != null ? lookupProperty(depth0,"eventSearchValue") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"eventSearchValue","hash":{},"data":data,"loc":{"start":{"line":40,"column":136},"end":{"line":40,"column":156}}}) : helper)))
    + "\" ";
},"11":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"eventList") : depths[1]),{"name":"each","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":10},"end":{"line":52,"column":19}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div\n              class=\"list-group-item eventsListItem clickable\"\n              id=\"eventsListItem-"
    + alias2(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":46,"column":33},"end":{"line":46,"column":43}}}) : helper)))
    + "\"\n            >\n              <span title=\"Fragmentation Id: "
    + alias2(lookupProperty(helpers,"lookup").call(alias1,depth0,(depths[1] != null ? lookupProperty(depths[1],"EVENT_ID") : depths[1]),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":48,"column":45},"end":{"line":48,"column":72}}}))
    + "\">\n                "
    + alias2(lookupProperty(helpers,"lookup").call(alias1,depth0,(depths[1] != null ? lookupProperty(depths[1],"OBJECT_COSPAR_ID") : depths[1]),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":49,"column":16},"end":{"line":49,"column":51}}}))
    + "\n              </span>\n            </div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "    <center><span class=\"fa fa-spinner fa-pulse fa-3x fa-fw margin-bottom\" style=\"margin:100px auto auto\"></span></center>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"selectFirstList\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"dataPopulated") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(14, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":61,"column":9}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true,"useDepths":true});
