/*
	Model for detail file list filter.
	Stores filters currently selected.
*/

var Backbone = require('backbone')

var DetailFilesFilterModel = Backbone.Model.extend({})

module.exports = DetailFilesFilterModel
