/*
	Model/collection to send and receive comments between user and admin
*/
const Backbone = require('backbone')

const AskAdminMessagesModel = Backbone.Model.extend({
  urlRoot: '/api/protected/askAdmin',
  idAttribute: 'messageId',
})

const AskAdminMessagesCollection = Backbone.Collection.extend({
  model: AskAdminMessagesModel,
  url() {
    let url = '/api/protected/askAdmin'
    const query = new URLSearchParams()
    if (this.eventId) {
      query.set('eventId', this.eventId)
    }
    if (this.userId) {
      query.set('userId', this.userId)
    }
    url += `?${query.toString()}`
    return url
  },

  initialize(options) {
    this.eventId = options.eventId
    this.userId = options.userId
    this.fetch({ reset: true })
  },

  parse(response) {
    return response
  },
})

module.exports = AskAdminMessagesCollection
