/*
	Model provids the necessary functionalities for event objects view
	e.g applying filters, sorting
*/
var Backbone = require('backbone')
var _ = require('lodash')

var EventsDataCollection = require('./EventsDataCollection')
var eventsFilterModel = require('../models/EventsFilterModel')
var eventsFollowCollection = require('../models/EventsFollowCollection')

var eventsColumns = require('../../../common/config/constants').eventsFieldNames

var FilterFactory = require('../../../common/js/factory/FilterFactory.js')
var Util = require('../../../common/js/helpers/Util.js')

var EventsModel = Backbone.Model.extend({
  eventsDataCollection: null,

  objectClasses: null,

  fullCollectionFilterAttributes: {},

  initialize() {
    this.eventsDataCollection = new EventsDataCollection({
      query: '',
      view: 'events',
    })
    this.displayCollection = new (Backbone.Collection.extend({
      model: Backbone.Model.extend({ idAttribute: 'messageId' }),
    }))()

    this.listenTo(eventsFilterModel, 'change', () => {
      console.log('react to eventsFilterModel change:filters')
      this.applyFilter()
    })

    this.listenTo(this.eventsDataCollection, 'sync add remove', () => {
      console.log('eventsDataCollection fetched, populating display Collection')

      // Add fields which is + of two objects in FDM
      let mergedItems = this.eventsDataCollection.toJSON()

      this.eventsDataCollectionJSON = Util.mergeExtraColumns(mergedItems)

      if (this.eventsDataCollectionJSON.length > 0) {
        // Append Followed Attribute
        this.appendIsFollowedAttribute()
      }

      this.initalizeFilterAttributes()
      this.displayCollection.reset(this.eventsDataCollectionJSON)
      this.trigger('change:eventsDataCollection')
    })

    this.listenTo(eventsFollowCollection, 'add remove', () => {
      // Append Followed Attribute
      this.appendIsFollowedAttribute()
      this.applyFilter()
    })
  },

  // initialize filter attributes on the main collection to keep list of all available options after filter applied.
  initalizeFilterAttributes() {
    var checkBoxObjectEventType = {
      list: _.uniq(
        _.map(this.eventsDataCollectionJSON, eventsColumns.EVENT_TYPE),
      ),
    }

    var rangeCataloguedObjects = {
      max: Math.ceil(
        _.max(_.map(this.eventsDataCollectionJSON, eventsColumns.CATALOGUED)),
      ),
      min: Math.floor(
        _.min(_.map(this.eventsDataCollectionJSON, eventsColumns.CATALOGUED)),
      ),
    }

    var rangeCataloguedOnOrbitObjects = {
      max: Math.ceil(
        _.max(
          _.map(
            this.eventsDataCollectionJSON,
            eventsColumns.CATALOGUED_ON_ORBIT,
          ),
        ),
      ),
      min: Math.floor(
        _.min(
          _.map(
            this.eventsDataCollectionJSON,
            eventsColumns.CATALOGUED_ON_ORBIT,
          ),
        ),
      ),
    }

    var rangeEvents1Cm = {
      max: Math.ceil(
        _.max(_.map(this.eventsDataCollectionJSON, eventsColumns.OBJECT_1CM)),
      ),
      min: Math.floor(
        _.min(_.map(this.eventsDataCollectionJSON, eventsColumns.OBJECT_1CM)),
      ),
    }

    var rangeEvents10Cm = {
      max: Math.ceil(
        _.max(_.map(this.eventsDataCollectionJSON, eventsColumns.OBJECT_10CM)),
      ),
      min: Math.floor(
        _.min(_.map(this.eventsDataCollectionJSON, eventsColumns.OBJECT_10CM)),
      ),
    }

    var checkBoxAttributes = {}
    checkBoxAttributes[eventsColumns.EVENT_TYPE] = checkBoxObjectEventType

    var rangeAttributes = {}
    rangeAttributes[eventsColumns.CATALOGUED] = rangeCataloguedObjects
    rangeAttributes[eventsColumns.CATALOGUED_ON_ORBIT] =
      rangeCataloguedOnOrbitObjects
    rangeAttributes[eventsColumns.OBJECT_1CM] = rangeEvents1Cm
    rangeAttributes[eventsColumns.OBJECT_10CM] = rangeEvents10Cm

    this.fullCollectionFilterAttributes = {
      checkBox: checkBoxAttributes,
      range: rangeAttributes,
    }
  },

  // add what events are followed
  appendIsFollowedAttribute() {
    var followedEvents = _.map(
      eventsFollowCollection.toJSON(),
      eventsColumns.EVENT_ID,
    )
    for (var i = 0; i < this.eventsDataCollectionJSON.length; i++) {
      if (
        followedEvents.length > 0 &&
        followedEvents.includes(
          this.eventsDataCollectionJSON[i][eventsColumns.EVENT_ID],
        )
      ) {
        this.eventsDataCollectionJSON[i][eventsColumns.IS_FOLLOWED] = true
      } else {
        this.eventsDataCollectionJSON[i][eventsColumns.IS_FOLLOWED] = false
      }
    }
  },

  applyFilter() {
    let filteredItems = this.eventsDataCollectionJSON

    _.forEach(eventsFilterModel.toJSON(), (value, key) => {
      if (
        key !== 'sort' &&
        key !== 'followedFilter' &&
        ((value.hasOwnProperty('text') && value.text !== '') ||
          !value.hasOwnProperty('text'))
      ) {
        filteredItems = _.filter(filteredItems, (item) => {
          let field = key
          if (key.split('-')[0] === eventsColumns.EVENT_DATE) {
            field = eventsColumns.EVENT_DATE
          } else if (key.split('-')[0] === eventsColumns.CREATION_DATE) {
            field = eventsColumns.CREATION_DATE
          }
          if (FilterFactory.applyFilter(value, item[field])) {
            return item
          }
        })
      }
    })

    if (eventsFilterModel.has('sort')) {
      const itemClicked = eventsFilterModel.get('sort').split('|')
      if (itemClicked[0] === 'asc') {
        filteredItems = this.sortAscending(itemClicked[1], filteredItems)
      } else {
        filteredItems = this.sortDescending(itemClicked[1], filteredItems)
      }
    }

    if (eventsFilterModel.has('followedFilter')) {
      filteredItems = _.filter(filteredItems, eventsColumns.IS_FOLLOWED)
    }

    this.displayCollection.reset(filteredItems)
  },

  validateItemWithFilter(item, filterKey) {
    var filterValue = eventsFilterModel.attributes[filterKey]
    if (filterValue.hasOwnProperty('text')) {
      if (item[filterKey].toString().startsWith(filterValue.text)) {
        return true
      } else {
        return false
      }
    }
  },

  getCheckList(checkListField) {
    const displayResult = _.uniq(
      _.map(this.displayCollection.toJSON(), checkListField),
    )
    let returnResult = []
    if (this.fullCollectionFilterAttributes.hasOwnProperty('checkBox')) {
      const categories =
        this.fullCollectionFilterAttributes.checkBox[checkListField].list

      returnResult = categories.map((category) => ({
        value: category,
        isChecked: _.includes(displayResult, category),
      }))
      returnResult = _.sortBy(returnResult, 'value')
    }
    return returnResult
  },

  getMaxValue(rangeField) {
    let result =
      Math.ceil(
        _.max(_.map(this.displayCollection.toJSON(), rangeField)) * 100,
      ) / 100
    result = isNaN(result) ? 0.0 : result
    return result
  },

  getMinValue(rangeField) {
    let result =
      Math.floor(
        _.min(_.map(this.displayCollection.toJSON(), rangeField)) * 100,
      ) / 100
    result = isNaN(result) ? 0.0 : result
    return result
  },

  getMaxRange(rangeField) {
    return this.fullCollectionFilterAttributes.hasOwnProperty('range')
      ? this.fullCollectionFilterAttributes.range[rangeField].max
      : ''
  },

  getMinRange(rangeField) {
    return this.fullCollectionFilterAttributes.hasOwnProperty('range')
      ? this.fullCollectionFilterAttributes.range[rangeField].min
      : ''
  },

  sortAscending(field, filteredItems) {
    return _.chain(filteredItems).sortBy(field).value()
  },

  sortDescending(field, filteredItems) {
    return _.chain(filteredItems).sortBy(field).reverse().value()
  },

  getDisplayCollection() {
    return this.displayCollection
  },

  getEventsDataCollection() {
    return this.eventsDataCollection
  },

  getEventsDataByEvent(eventId) {
    var filterObject = {}
    filterObject[eventsColumns.EVENT_ID] = Number(eventId)
    return _.first(_.filter(this.displayCollection.toJSON(), filterObject))
  },
})

var eventsModel = new EventsModel()

// make it a singleton
module.exports = eventsModel
