// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li id=\"helpLink\"><a href=\"/files/public/GEN-SW-MAN-00256-OPS-SD.pdf\">Documentation</a></li>\n<li class=\"dropdown\" id=\"NavigationView\">\n	<a href=\"#\" class=\"dropdown-toggle\" data-toggle=\"dropdown\">\n		<span class=\"glyphicon glyphicon-user\"></span>\n		"
    + alias4(((helper = (helper = lookupProperty(helpers,"userName") || (depth0 != null ? lookupProperty(depth0,"userName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userName","hash":{},"data":data,"loc":{"start":{"line":5,"column":2},"end":{"line":5,"column":14}}}) : helper)))
    + "\n		<span class=\"caret\"></span>\n	</a>\n	<div class=\"dropdown-menu panel panel-default nav-user\" role=\"menu\" style=\"min-width:190px\">\n			<div class=\"panel-body\">\n				<div>\n					<strong>"
    + alias4(((helper = (helper = lookupProperty(helpers,"userName") || (depth0 != null ? lookupProperty(depth0,"userName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userName","hash":{},"data":data,"loc":{"start":{"line":11,"column":13},"end":{"line":11,"column":25}}}) : helper)))
    + "</strong>\n				</div>\n				<div>\n					<small>"
    + alias4(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"email","hash":{},"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":14,"column":21}}}) : helper)))
    + "</small>\n				</div>\n			</div>\n			<div class=\"panel-footer\">\n				<div class=\"btn-group\" role=\"group\">\n					<a class=\"btn btn-default btn-sm\" data-toggle=\"modal\" data-backdrop=\"static\" data-target=\"#settings-modal\">\n						<span class=\"glyphicon glyphicon-cog\"></span>\n						Settings\n					</a>\n					<a class=\"btn btn-primary btn-sm pull-right\" href=\"/logout\">\n						<span class=\"glyphicon glyphicon-log-out\"></span>\n						Logout\n					</a>\n				</div>\n			</div>\n	</div>\n</li>\n";
},"useData":true});
