/*
	Global Detail View which populates the required sub-detail views
	e.g event info, list of files for the event, ask admin.
*/
var Backbone = require('backbone')
var _ = require('lodash')
var $ = require('jquery')
var moment = require('moment')

let { DetailFilesFilterView } = require('./DetailFilesFilterView')
let { DetailFilesItemView } = require('./DetailFilesItemView')
let { DetailFilesUploadItemView } = require('./DetailFilesUploadItemView')
let { AskAdminView } = require('../../../common/js/views/AskAdminView')

var EventsDataCollection = require('../models/EventsDataCollection')
var EventsSimulationCollection = require('../models/EventsSimulationCollection')

var DetailModel = require('../models/DetailModel')
var DetailFilesItemModel = require('../models/DetailFilesItemModel')
var DetailFilesFilterModel = require('../models/DetailFilesFilterModel')

var Util = require('../../../common/js/helpers/Util.js')

var eventsColumns = require('../../../common/config/constants').eventsFieldNames

var DetailView = (exports.DetailView = Backbone.View.extend({
  template: require('../../templates/DetailView.hbs'),

  whoAmI: require('../../../common/js/models/WhoAmI'),

  askAdminWindowOpened: false,

  detailDataPopulated: false,

  simulationObject1DataPopulated: false,

  simulationObject2DataPopulated: false,

  simulationCombinedDataPopulated: false,

  detailSimulationCollection: {},

  initialize(options) {
    console.log('Initialized Detail View')

    this.eventId = options.eventId
    this.isAdmin = options.userRights === 'admin'

    this.detailEventCollection = new EventsDataCollection({
      query: eventsColumns.EVENT_ID + '==' + this.eventId,
      view: 'detail',
    })

    this.askAdminView = new AskAdminView({
      eventId: this.eventId,
      userId: this.whoAmI.toJSON().userId,
      isAdminUser: this.isAdmin,
      view: 'detail',
    })

    this.render()

    this.listenTo(this.detailEventCollection, 'reset', function (e) {
      let mergedItems = this.detailEventCollection.toJSON()

      // Get Object1 Simulation Collection
      if (
        mergedItems[0][eventsColumns.OBJECT1_BUSTER_ID] !== null &&
        mergedItems[0][eventsColumns.OBJECT1_BUSTER_ID] !== ''
      ) {
        this.detailObject1SimulationCollection = new EventsSimulationCollection(
          {
            id: mergedItems[0][eventsColumns.OBJECT1_BUSTER_ID],
          },
        )
      } else {
        this.simulationObject1DataPopulated = true
      }

      // Get Object2 Simulation Collection
      if (
        mergedItems[0][eventsColumns.OBJECT2_BUSTER_ID] !== null &&
        mergedItems[0][eventsColumns.OBJECT2_BUSTER_ID] !== ''
      ) {
        this.detailObject2SimulationCollection = new EventsSimulationCollection(
          {
            id: mergedItems[0][eventsColumns.OBJECT2_BUSTER_ID],
          },
        )
      } else {
        this.simulationObject2DataPopulated = true
      }

      // Get Combined Simulation Collection
      if (
        mergedItems[0][eventsColumns.BUSTER_ID] !== null &&
        mergedItems[0][eventsColumns.BUSTER_ID] !== ''
      ) {
        this.detailCombinedSimulationCollection =
          new EventsSimulationCollection({
            id: mergedItems[0][eventsColumns.BUSTER_ID],
          })
      } else {
        this.simulationCombinedDataPopulated = true
      }

      this.listenTo(
        this.detailObject1SimulationCollection,
        'reset',
        function (e) {
          this.simulationObject1DataPopulated = true
          this.detailSimulationCollection[
            this.detailEventCollectionJSON[0][eventsColumns.OBJECT1_COSPAR_ID]
          ] = this.detailObject1SimulationCollection
          this.render()
        },
      )

      this.listenTo(
        this.detailObject2SimulationCollection,
        'reset',
        function (e) {
          this.simulationObject2DataPopulated = true
          this.detailSimulationCollection[
            this.detailEventCollectionJSON[0][eventsColumns.OBJECT2_COSPAR_ID]
          ] = this.detailObject2SimulationCollection
          this.render()
        },
      )

      this.listenTo(
        this.detailCombinedSimulationCollection,
        'reset',
        function (e) {
          this.simulationCombinedDataPopulated = true
          this.detailSimulationCollection[
            this.detailEventCollectionJSON[0][eventsColumns.OBJECT_COSPAR_ID]
          ] = this.detailCombinedSimulationCollection
          this.render()
        },
      )

      this.detailEventCollectionJSON = Util.mergeExtraColumns(mergedItems)

      this.detailDataPopulated = true

      this.render()
    })
  },

  events: {
    'click .askAdminPanelHeading-detail': 'detailAskAdminPanelHeading',
    'click button.uploadFileButton': 'uploadFileButton',
  },

  renderUploadFile() {
    if (this.isAdmin) {
      this.el
        .querySelector('.detailUploadModal')
        .appendChild(this.detailFilesUploadItemView.render().el)
      return this
    }
  },

  renderAskAdmin() {
    if (this.el.querySelector('.askAdminWindow-detail')) {
      this.$el.find('.askAdminWindow-detail').remove()
    }

    this.el
      .querySelector('.detailAskAdminView')
      .appendChild(this.askAdminView.render(this.askAdminWindowOpened).el)
  },

  renderFiles() {
    this.el
      .querySelector('.detailFilesFilter')
      .appendChild(this.detailFilesFilterView.render().el)
    return this
  },

  renderDetailItem(divHeight) {
    this.el
      .querySelector('.detailFilesItem')
      .appendChild(this.detailFilesItemView.render(false).el)
    return this
  },

  render() {
    this.filterDivHeight = this.$el.parent().height() - 90
    var markup = this.template({
      filterDivHeight: this.filterDivHeight + 'px',
      dataPopulated:
        this.simulationObject1DataPopulated &&
        this.simulationObject2DataPopulated &&
        this.simulationCombinedDataPopulated &&
        this.detailDataPopulated
          ? true
          : false,
    })
    this.$el.html(markup)
    if (
      this.simulationObject1DataPopulated &&
      this.simulationObject2DataPopulated &&
      this.simulationCombinedDataPopulated &&
      this.detailDataPopulated
    ) {
      var detailFilesItemModel = new DetailFilesItemModel()
      var detailFilesFilterModel = new DetailFilesFilterModel()

      var eventEventsDateHistory = _.map(
        this.detailEventCollectionJSON[0].content,
        eventsColumns.EVENT_DATE,
      )

      var detailModel = new DetailModel({
        eventId: this.eventId,
        detailFilesFilterModel,
        detailEventCollection: this.detailEventCollectionJSON,
      })

      if (this.isAdmin) {
        this.detailFilesUploadItemView = new DetailFilesUploadItemView({
          detailModel,
          cosparId: this.cosparId,
        })
      }

      this.detailFilesDivHeight = this.isAdmin
        ? this.$el.parent().height() - 210
        : this.$el.parent().height() - 150
      this.detailFilesFilterView = new DetailFilesFilterView({
        detailFilesFilterModel,
        detailFilesItemModel,
        detailModel,
        eventId: this.eventId,
        divHeight: this.detailFilesDivHeight,
        isAdmin: this.isAdmin,
        detailEventCollection: this.detailEventCollectionJSON,
        detailSimulationCollection: this.detailSimulationCollection,
      })

      this.detailFilesItemView = new DetailFilesItemView({
        detailModel,
        detailFilesItemModel,
        divHeight: this.$el.parent().height(),
        eventId: this.eventId,
        eventEventsDateHistory,
        detailEventCollection: this.detailEventCollectionJSON,
        detailSimulationCollection: this.detailSimulationCollection,
      })

      this.renderUploadFile()
      this.renderFiles()
      this.renderAskAdmin()
      this.renderDetailItem()
    }
    return this
  },

  uploadFileButton(e) {
    var currentDateTime = moment.utc().format('DD-MMM-YYYY HH:mm:ss')
    this.el.querySelector('input.uploadFileDate').value = currentDateTime
    return this
  },

  detailAskAdminPanelHeading(e) {
    if (!this.askAdminWindowOpened) {
      // if ask admin window closed, open it.
      // change the icon to opened in header
      // reduce filter and files div height
      this.$el.find('.expandDetailAskAdmin').remove()
      if (this.el.querySelector('.newMessages-detail') && !this.isAdmin) {
        this.askAdminView.updateNewMessageStatus()
        this.el.querySelector('.newMessages-detail').remove()
      }
      this.$el.find('#panelBodyAskAdmin-detail').collapse('show')
      this.detailFilesDivHeight = this.detailFilesDivHeight - 335
      this.filterDivHeight = this.filterDivHeight - 335
      this.el.querySelector('.detailFilterMenu').style.height =
        this.filterDivHeight + 'px'
      this.el.querySelector('.detailFilesMenu').style.height =
        this.detailFilesDivHeight + 'px'
      this.addPanelButton(
        'glyphicon glyphicon-triangle-top collapseDetailAskAdmin',
        '.askAdminPanelExpand-detail',
      )
      if (this.el.querySelector('.askAdminChatBox-detail')) {
        this.el.querySelector('.askAdminChatBox-detail').scrollTop =
          this.el.querySelector('.askAdminChatBox-detail').scrollHeight
      }
      this.askAdminWindowOpened = true
    } else {
      // if ask admin window opened, close it.
      // change the icon to closed in header
      // increase filter and files div height
      this.$el.find('.collapseDetailAskAdmin').remove()
      this.$el.find('#panelBodyAskAdmin-detail').collapse('hide')
      this.detailFilesDivHeight = this.detailFilesDivHeight + 335
      this.filterDivHeight = this.filterDivHeight + 335
      this.el.querySelector('.detailFilterMenu').style.height =
        this.filterDivHeight + 'px'
      this.el.querySelector('.detailFilesMenu').style.height =
        this.detailFilesDivHeight + 'px'
      this.addPanelButton(
        'glyphicon glyphicon-triangle-right expandDetailAskAdmin',
        '.askAdminPanelExpand-detail',
      )
      this.askAdminWindowOpened = false
    }
    e.preventDefault()
    e.stopPropagation()
  },

  addPanelButton(spanClass, appendClass) {
    var span = document.createElement('span')
    span.className += spanClass

    this.el.querySelector(appendClass).appendChild(span)
  },
}))
